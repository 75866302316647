/**
 * Saves a file to the Downloads folder for Desktop devices. The download is done by creating a HTML A object with the URL and clicking on it.
 * @param fileName The name to be use for the download file
 * @param blob  The content to download.
 */
export const saveFileToDownloadsFolder = (fileName: string, blob: Blob) => {
  // Create a HTML A object with the URL and click on it in order to trigger to download.
  const url = window.URL.createObjectURL(
    new Blob([ blob ]),
  )
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  // Append to html link element page
  document.body.appendChild(link)
  // Start download
  link.click()
  // Remove the link
  link.parentNode?.removeChild(link)
}
