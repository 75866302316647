export const asyncForEach = async <T, X>(array: T[], callback: (each: T, index: number, array: T[]) => Promise<X>) => {
  const results = [] as X[]

  for (let index = 0; index < array.length; index++) {
    const result = await callback(array[index], index, array)
    results.push(result)
  }

  return results
}
