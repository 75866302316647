type predicateFn<T> = Parameters<T[]["findIndex"]>[0]

export function upsertIn<T>(inputArray: T[], matchFn: predicateFn<T>, replacement: T): T[] {
  const nextData = inputArray.slice()

  const updatedIx = nextData.findIndex(matchFn)

  if (updatedIx === -1) {
    nextData.push(replacement)
  } else {
    nextData.splice(updatedIx, 1, replacement)
  }

  return nextData
}
