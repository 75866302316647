
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import { DataGrid } from '@mui/x-data-grid/DataGrid'
import { GridColDef } from '@mui/x-data-grid/models/colDef'
import { Alert, AlertTitle, Button, ButtonGroup, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import React, { ChangeEvent, useMemo, useState } from 'react'
import { useCoreApiSource } from '../../../common/hooks/useCoreApiSource'
import { useListAllTaskTemplatesQuery, ListAllTaskTemplatesQuery, useCreateTaskTemplateMutation, TaskTemplateTrigger } from '../../../graphql/generated'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useNavigate } from "react-router-dom"
import AddIcon from '@mui/icons-material/Add'
import { useWeaverFlags } from '../../../api/thirdparty/launchDarkly/useWeaverFlags'
import Box from '@mui/system/Box'
import { z } from 'zod'
import { useForm, UseFormReturn, Controller } from "react-hook-form"
import { zodResolver } from '@hookform/resolvers/zod'
import { DateTime } from 'luxon'

// HACK: The default task template contains some documentation on how to use the substitution.
// HACK: This is taking advantage of liquidjs's markup features, and should be replaced by a more robust form of active documentation
const EMPTY_TASK_TEMPLATE_BODY = `{% comment %}
Example fields:
{
  currentTeam: {
    "id": "161b6a61-da0d-409b-98ee-bb7ce354920b",
    "companyNumber": null,
    "createdAt": "2023-01-11T17:34:58.874Z",
    "isOnboardingComplete": true,
    "type": "HOMEOWNER",
    "updatedAt": "2023-01-11T17:34:58.874Z"
  },
  project: {
    "id": "e952c117-8bcb-4feb-b85d-39643cba3aea",
    "address": {
     "addressLine1": "34 Grosvenor Square",
     "country": "United Kingdom",
     "postCode": "W1K 2HD",
     "postTown": "London"
    },
    "budgetCategory": "NoIdea",
    "budgetValue": {
     "amountInPence": "23000000",
     "currency": "GBP"
    },
    "createdAt": "2023-01-11T17:35:23.219Z",
    "description": "Description",
    "documentCount": 1,
    "documentIds": [
     "2ba29950-6bd7-419b-b9b2-6ab45333b2f8"
    ],
    "projectTypes": [
     "SideExtension"
    ],
    "reference": "",
    "status": "ACTIVE",
    "statusId": null,
    "tenderReturnDate": "2023-01-24T17:35:00-00:00",
    "title": "34 Grosvenor Square",
    "updatedAt": "2023-01-11T20:41:43.972Z",
    "workStartEstimate": "OVER_SIX_MONTHS",
    "_notesCount": 0
  }
{% endcomment %}

{ "tasks": [] }`

const createFormSchema = z.object({
  id: z.string().min(3),
  trigger: z.nativeEnum(TaskTemplateTrigger),
})

type FormSchema = z.infer<typeof createFormSchema>

type SubmitHandler = Parameters<UseFormReturn<FormSchema>["handleSubmit"]>[0]

type TaskTemplate = ListAllTaskTemplatesQuery["listAllTaskTemplates"][number]
const TaskTemplatesIndex: React.FC = () => {
  const gqlDatasource = useCoreApiSource()
  const navigate = useNavigate()
  const currentTaskTemplateFlag = useWeaverFlags()["task-templates"]

  const [ createModalOpen, setCreateModalOpen ] = useState<boolean>(false)

  const query = useListAllTaskTemplatesQuery(gqlDatasource, {}, { refetchOnWindowFocus: false })

  const { register, handleSubmit, reset,  formState: { errors, defaultValues, isSubmitting }, control } = useForm<FormSchema>({
    defaultValues: {
      id: "",
      trigger: TaskTemplateTrigger.CreateProject,
    },
    resolver: zodResolver(createFormSchema),
  })

  const columns = useMemo<GridColDef<TaskTemplate>[]>(() => [
    { field: "id", headerName: 'Name', flex: 1 },
    { field: "trigger", headerName: "Trigger", flex: 1 },
    { field: "updatedAt", headerName: 'Last Updated', flex: 1, valueFormatter: params => DateTime.fromISO(params.value).toRelative() },
  ], [])

  const handleOpenDialog = () => { setCreateModalOpen(true) }
  const handleCancelCreate = () => {
    setCreateModalOpen(false)
    reset({ ...defaultValues })
  }

  const createTaskTemplateMutation = useCreateTaskTemplateMutation(gqlDatasource)

  const createTaskTemplate: SubmitHandler = async (taskTemplate) => {
    await createTaskTemplateMutation.mutateAsync({ input: { id: taskTemplate.id, body: EMPTY_TASK_TEMPLATE_BODY, trigger: taskTemplate.trigger } }, {
      onSuccess: () => {
        query.refetch()
        handleCancelCreate()
        createTaskTemplateMutation.reset()
      },
    })
  }

  const data = query.data?.listAllTaskTemplates || []

  return (
    <Grid container rowSpacing={2} p={2}>
      <Grid item xs={12}>
        <Alert variant="outlined" severity="info">
          <AlertTitle>Task Templates</AlertTitle>
          <p>When unset, the default values are <code>acceptLead_default</code> and <code>createProject_default</code></p>
          <p>Task Templates should start with the name of their trigger, <code>createProject_mytemplate</code></p>

          <h4>Current Flag Value</h4>
          <pre>{JSON.stringify(currentTaskTemplateFlag)}</pre>
        </Alert>
        <Box sx={{ fontFamily: "monospace" }}></Box>
      </Grid>
      <Grid item xs={12}>
        <ButtonGroup sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button sx={{ mt: '2rem' }} variant="contained" onClick={handleOpenDialog}>Add Template<AddIcon /></Button>
        </ButtonGroup>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <DataGrid
                  getRowId={row => row.id}
                  onRowClick={(row) => navigate(`/engineering/taskTemplates/${row.id}`)}
                  rows={data}
                  columns={columns}
                  disableSelectionOnClick={true}
                  rowHeight={110}
                  autoHeight={true}
                  disableColumnMenu
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Dialog fullWidth={true} open={createModalOpen} onClose={handleCancelCreate}>
        <DialogTitle>Create Task Template</DialogTitle>
        <DialogContent>
          {createTaskTemplateMutation.error ? <Alert severity="error"><>
            <AlertTitle>Failed to Create Template</AlertTitle>
            {createTaskTemplateMutation.error.toString()}
          </>
          </Alert> : null
          }
          <TextField
            sx={{ mb: '2rem' }}
            autoFocus
            margin="dense"
            type="text"
            label="Template ID"
            fullWidth
            variant="standard"
            error={!!errors.id}
            helperText={errors.id?.message}
            {...register("id")}
          />

          <InputLabel id="label">Task Template Trigger</InputLabel>
          <Controller
            control={control}
            name="trigger"
            render={({
              formState: { isSubmitting },
              field: { onChange, value },
              fieldState: { error },
            }) => (
              <>
                <Select
                  disabled={isSubmitting}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                >
                  {Object.values(TaskTemplateTrigger).map(value => (
                    <MenuItem key={value} value={value}>{value}</MenuItem>
                  ))}
                </Select>
                {error ? <Typography color='error.main'>{error.message}</Typography> : null}
              </>
            )}
          />

          {errors.trigger && <div>{errors.trigger.message}</div>}
        </DialogContent>
        <DialogActions>
          <Button disabled={isSubmitting} onClick={handleCancelCreate}>Cancel</Button>
          <Button disabled={isSubmitting} color='primary' onClick={handleSubmit(createTaskTemplate)}>Create</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

export default TaskTemplatesIndex
