import styled from "@emotion/styled"
import { MenuItem, MenuItemProps, Button, IconButton } from "@mui/material"
import { forwardRef, RefObject } from "react"

const StyledMenuItem = styled(MenuItem)({
  paddingLeft: '4px',
  paddingRight: '4px',
})

type IconMenuItemProps = {
  leftIcon?: React.ReactNode,
  rightIcon?: React.ReactNode,
  onClick?: () => void,
  label?: string,
  className?: string,
  MenuItemProps?: MenuItemProps,
  ref?: RefObject<HTMLLIElement>,

  /**
   * isIconOnly prop
   * It renders the button menu as a Icon only, using the righticon prop. It has no label or styling of the regular button in this context
   */
  isIconOnly?: boolean,
}

const ButtonMenuItem = forwardRef<HTMLLIElement, IconMenuItemProps>(
  ({ leftIcon, rightIcon, label, MenuItemProps, isIconOnly, className, ...props }, ref) => {
    return (
      <StyledMenuItem
        {...MenuItemProps}
        ref={ref}
        className={className}
        {...props}
      >
        {isIconOnly && (
          <IconButton size="small">
            {rightIcon}
          </IconButton>
        )}
        {!isIconOnly && (
          <Button variant="contained" color="primary" startIcon={leftIcon} endIcon={rightIcon} size="small" fullWidth>
            {label}
          </Button>
        )}

      </StyledMenuItem>
    )
  },
)

ButtonMenuItem.displayName = 'ButtonMenuItem'
export { ButtonMenuItem }
