import { zodResolver } from "@hookform/resolvers/zod"
import { Alert, AlertTitle, Card, CardContent, Chip, TextField } from "@mui/material"
import Button from "@mui/material/Button"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Typography from "@mui/material/Typography"
import { useQueryClient } from "@tanstack/react-query"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { useAnalyticsEvent } from "../../../common/hooks/analytics"
import { useCoreApiSource } from "../../../common/hooks/useCoreApiSource"
import { useRequiredContext } from "../../../common/hooks/useRequiredContext"
import { ListContractorProfileNotesDocument, TeamStatus, useListContractorProfileNotesQuery, useSetTeamStatusMutation } from "../../../graphql/generated"
import { BuildersIndexContext } from "../BuildersIndexContext"
import { SuspendState } from "../types"

const toggleSuspendFormSchema = z.object({
  reason: z.string().min(1, "Please provide a reason for this action"),
})
type ToggleSuspendForm = z.infer<typeof toggleSuspendFormSchema>

const SuspendToggle: React.FC<{modalState: SuspendState}> = ({ modalState }) => {
  const gqlDatasource = useCoreApiSource()
  const queryClient = useQueryClient()
  const sendContractorSuspendedEvent = useAnalyticsEvent("Ops_Contractor_Suspended_Toggle")

  const { handleModalSuccess, handleModalCancel } = useRequiredContext(BuildersIndexContext)

  const contractor = modalState.contractor
  const contractorName = contractor.companyTradingAs || contractor.companyRegisteredName

  const { register, handleSubmit, reset,  formState: { errors, defaultValues, isSubmitting }, control } = useForm<ToggleSuspendForm>({
    defaultValues: {
    },
    resolver: zodResolver(toggleSuspendFormSchema),
  })

  const action = contractor.team.status === TeamStatus.Suspended ? {
    statusChip: <Chip variant="outlined" label="Suspended" color="warning" />,
    summary: "restore this contractor to the platform",
    title: "Restore",
    color: "success",
    status: TeamStatus.Active,
  } as const : {
    statusChip: <Chip variant="outlined" label="Active" color="success" />,
    alert: <Alert severity="error"><AlertTitle>You are about to suspend this contractor from the platform.</AlertTitle>This will disrupt service for this user and prevent them from using the app.</Alert>,
    summary: "suspend this contractor from the platform",
    title: "Suspend",
    color: "error",
    status: TeamStatus.Suspended,
  } as const
  const setTeamStatus = useSetTeamStatusMutation(gqlDatasource, { onSuccess: async (data, { teamId, reason, status }) => {
    await sendContractorSuspendedEvent({ teamId, reason, status })
    await queryClient.invalidateQueries(useListContractorProfileNotesQuery.getKey({ id: teamId }))
  } })

  const onSubmit = handleSubmit(async ({ reason }) => {
    await setTeamStatus.mutateAsync({ teamId: contractor.id, status: action.status, reason })
    handleModalSuccess(contractor.id)
  })

  return <>
    <DialogContent>
      {action.alert}
      <Card sx={{ mt: 2, mb: 2, minWidth: 400 }}>
        <CardContent>
          <Typography variant="h5" component="div">{ contractorName }</Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>{contractor.companyNumber}</Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom> Current Status: {action.statusChip}</Typography>
        </CardContent>
      </Card>

      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Reason for action</Typography>
      <TextField type="textarea" disabled={setTeamStatus.isLoading} multiline={true} sx={{ width: "100%" }} {...register("reason")} />
      {errors.reason && <Typography color="error">{errors.reason.message}</Typography>}

    </DialogContent>
    <DialogActions>
      <Button onClick={handleModalCancel}>Cancel</Button>
      <Button color={action.color} variant="contained" onClick={onSubmit}>{action.title}</Button>
    </DialogActions>
  </>
}

export default SuspendToggle
