import React from "react"

import { Project, ProjectMember, ProjectMemberInvite, ProjectMemberSlot } from "./types"

export type ProjectsIndexContextValue = {
  handleManageMember: (project: Project, data: ProjectMember) => unknown | void,
  handleManageInvite: (project: Project, data: ProjectMemberInvite) => unknown | void,
  handleManageSlot: (project: Project, data: ProjectMemberSlot) => unknown | void,
  handleManageAddProjectInvite: (project: Project) => unknown | void,
  handleAddSlot: (project: Project) => unknown | void,
  handleModalSuccess: (projectId: string) => unknown | void,
  handleModalCancel: () => unknown|void,
}
export const ProjectsIndexContext = React.createContext<ProjectsIndexContextValue|undefined>(undefined)