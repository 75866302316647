import { objectEntries } from "./objects"

export const getEnumValueFromString = <T extends object>(type: T, value: string): T[keyof T] | undefined => {
  for (const [ typeKey, typeValue ] of objectEntries(type)) {
    if (typeValue === value) {
      return type[typeKey]
    }
  }

  return undefined
}
