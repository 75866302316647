import { useMutation } from "@tanstack/react-query"
import { useContext } from "react"
import { AuthContext, EnvContext } from "../../Bootstrapper"
import { BudgetRange, Money, ProgressionStatus, ProjectProgress, ProjectType, TeamType, WorkStartEstimate } from "../../graphql/generated"

type DataSource = {
  auth: { token: string },
  baseUrl: string,
}

type BuildFetcherArgs = {
  dataSource: DataSource,
  endpoint: string,
  requestInit?: RequestInit,
}

const buildMutateFetcher = <TData, TBody>({ dataSource, endpoint, requestInit }: BuildFetcherArgs) => {
  const Authorization = `Bearer ${dataSource.auth.token || ``}`
  const endpointHref = new URL(endpoint, dataSource.baseUrl).href

  return async (body: TBody): Promise<TData> => {
    const res = await fetch(endpointHref, {
      method: 'POST',
      headers: {
        Authorization,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      ...requestInit,
    })

    if (!res.ok) {
      throw new Error('Matching API response was not ok')
    }

    const json = await res.json()

    if (json.errors) {
      const { message } = json.errors[0]

      throw new Error(message)
    }

    return json
  }
}

type BudgetRangeSubscription = {
  budget_range: string,
  status: boolean,
  stripe_status: string | null ,
}

export type Invite = {
  team_id: string | null,
  project_id: string | null,
  budget_range?: BudgetRange | undefined,
  assigneeTeamId?: string | null,
  id: string,
  lastActivity?: string | null,
  description: string,
  budgetCategory: string,
  status?: ProjectProgress | null,
  statusId?: string | null,
  workStartEstimate: WorkStartEstimate,
  tenderReturnDate: string,
  projectTypes: Array<ProjectType>,
  title: string,
  budgetValue: Money,
}

export type KeyedInvite = {
  [budget_range: string]: Invite[],
}

type ContractorRecord = {
  team_id: string,
  name: string | null,
  company_status: string | null,
  company_status_readable: string | null,
  vetting_status: string | null,
  vetting_status_readable: string | null,
  payment_email: string | null,
  email: string | null,
  id: string,
  locations: string[],
  invites_sent: KeyedInvite,
  invites_accepted: KeyedInvite,
  invites_rejected: KeyedInvite,
  budget_ranges: BudgetRangeSubscription[],
  work_history: {
    projectTypes?: string[] | null,
    constructionValue?: Money | null,
    references?: {
      teamType: TeamType,
      status: ProgressionStatus,
    }[] | null,
  }[],
  total_spent: number,
  total_refunded: number,
  emails_sent: number,
  links_clicked: number,
  sms_sent: number,
}

export type ExtendedItemStructure = {
  value: any,
  weight: number,
  error?: string,
}

type ExtendedItem<T> = {
  value: T,
  weight: number,
  error?: string,
}

export type BuildersSearchRecord = {
  id: string,
  total_weight: number,
  team_id: ExtendedItem<ContractorRecord['team_id']>,
  name: ExtendedItem<ContractorRecord['name']>,
  locations: ExtendedItem<ContractorRecord['locations'] | null>,
  company_status: ExtendedItem<ContractorRecord['company_status']>,
  company_status_readable: ExtendedItem<ContractorRecord['company_status_readable']>,
  vetting_status: ExtendedItem<ContractorRecord['vetting_status']>,
  vetting_status_readable: ExtendedItem<ContractorRecord['vetting_status_readable']>,
  budget_ranges: ExtendedItem<ContractorRecord['budget_ranges']>,
  work_history: ExtendedItem<ContractorRecord['work_history']>,
  total_spent: ExtendedItem<ContractorRecord['total_spent']>,
  total_refunded: ExtendedItem<ContractorRecord['total_refunded']>,
  payment_email: ExtendedItem<ContractorRecord['payment_email']>,
  email: ExtendedItem<ContractorRecord['email']>,
  emails_sent: ExtendedItem<ContractorRecord['emails_sent']>,
  links_clicked: ExtendedItem<ContractorRecord['links_clicked']>,
  sms_sent: ExtendedItem<ContractorRecord['sms_sent']>,
  invites_sent: ExtendedItem<ContractorRecord['invites_sent']>,
  invites_accepted: ExtendedItem<ContractorRecord['invites_accepted']>,
  invites_rejected: ExtendedItem<ContractorRecord['invites_rejected']>,
}

type BuildersRequestBody = {
  locations?: string[],
  projectTypes?: string[] | undefined,
  from?: string | undefined,
  to?: string | undefined,
  budgetRange?: string | undefined,
  preference?: string | undefined,
  raw?: boolean | undefined,
  limit?: number | undefined,
}

export const useBuildersSearchMutate = (dataSource: DataSource) => {
  return useMutation(buildMutateFetcher<BuildersSearchRecord[], BuildersRequestBody>({
    dataSource,
    endpoint: '/builders/search',
  }))
}

export const useMatchApiDataSource = (): DataSource => {
  const env = useContext(EnvContext)
  const auth = useContext(AuthContext)

  if (!auth || !env) {
    throw new Error("useMatchApi can only be used inside of a ready auth and env context")
  }

  return {
    auth,
    baseUrl: env.REACT_APP_MATCHING_API_URL,
  }
}
