
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { UseFormReturn, useForm, Controller } from 'react-hook-form'
import { useCoreApiSource } from '../../../common/hooks/useCoreApiSource'
import { useGetTaskTemplateQuery, useUpdateTaskTemplateMutation } from '../../../graphql/generated'

import Editor from "@monaco-editor/react"
import Box from '@mui/material/Box'
import { useAnalyticsEvent } from '../../../common/hooks/analytics'

const createFormSchema = z.object({
  body: z.string().min(3),
})
type FormSchema = z.infer<typeof createFormSchema>

type SubmitHandler = Parameters<UseFormReturn<FormSchema>["handleSubmit"]>[0]

const editTaskTemplate: React.FC = () => {
  const { taskTemplateId } = useParams<{ taskTemplateId: string }>()
  const navigate = useNavigate()
  const fireUpdateTaskTemplateEvent = useAnalyticsEvent("Ops_TaskTemplates_TaskTemplate_Update")

  if (!taskTemplateId) throw new Error("Missing route Param: taskTemplateId")

  const gqlDataSource = useCoreApiSource()
  const { handleSubmit, reset, formState: { isSubmitting }, control } = useForm<FormSchema>({
    defaultValues: {
      body: undefined,
    },
    resolver: zodResolver(createFormSchema),
  })

  const query = useGetTaskTemplateQuery(gqlDataSource, { id: taskTemplateId }, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      reset({
        body: data.getTaskTemplate?.body,
      })
    },
  })
  const data = query.data?.getTaskTemplate

  const useUpdateTaskTemplate = useUpdateTaskTemplateMutation(gqlDataSource, {
    onSuccess: (data, { input: { id, trigger, body } }) => {
      fireUpdateTaskTemplateEvent({ id, trigger, body })
    },
  })

  const backToTaskTemplateIndex = () => navigate("/engineering/taskTemplates")

  const updateTaskTemplate: SubmitHandler = async (submitted) => {
    if (!data) throw new Error("Attempting to update task template before data is loaded")

    await useUpdateTaskTemplate.mutateAsync({ input: { id: taskTemplateId, body: submitted.body, trigger: data.trigger } }, {
      onSuccess: () => {
        backToTaskTemplateIndex()
      },
    })
  }

  if (query.isLoading) {
    return <Card>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Loading...</Typography>
      </CardContent>
    </Card>
  }

  return (
    <Card>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Editing {data?.id} (trigger: {data?.trigger})</Typography>
        <Box>
          {useUpdateTaskTemplate.error ? <Alert severity="error"><>
            <AlertTitle>Failed to Save Template</AlertTitle>
            {useUpdateTaskTemplate.error.toString()}
          </>
          </Alert> : null
          }
          <Controller
            control={control}
            name="body"
            render={({
              formState: { isSubmitting },
              field: { onChange, value },
              fieldState: { error },
            }) => (
              <>
                <Editor
                  height="calc(100vh - 300px)"
                  defaultLanguage="liquid"
                  value={value}
                  theme="vs-dark"
                  onChange={onChange}
                  options={{
                    scrollBeyondLastLine: false,
                    readOnly: isSubmitting,
                  }}
                />
                {error ? <Typography color='error.main'>{error.message}</Typography> : null}
              </>
            )}
          />
          <ButtonGroup sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
            <Button disabled={isSubmitting} variant="outlined"  onClick={backToTaskTemplateIndex}>Cancel</Button>
            <Button disabled={isSubmitting} variant="contained" onClick={handleSubmit(updateTaskTemplate)}>Save</Button>
          </ButtonGroup>
        </Box>
      </CardContent>
    </Card>
  )
}

export default editTaskTemplate
