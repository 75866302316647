import { Stack, Tooltip, Typography } from "@mui/material"
import { PropsWithChildren } from "react"
import ReportProblemIcon from '@mui/icons-material/ReportProblem'

const WeightLabel: React.FC<PropsWithChildren<{ error?: string }>> = ({ error, children }) => {
  return (
    <Stack direction="row" sx={{ position: 'absolute', top: 2, left: 0, right: 0, p: '2px', borderBottom: '1px solid lightgray', color: 'gray' }}>
      <Typography mr={1}>Weight: {children}</Typography>
      {error && (
        <Tooltip title={error}><ReportProblemIcon /></Tooltip>
      )}
    </Stack>
  )
}

export default WeightLabel
