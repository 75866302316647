import React, { useMemo } from 'react'
import { Box, Button, ButtonGroup, Card, CardContent, Grid, LinearProgress, Popover, Typography } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useCoreApiSource } from '../../../common/hooks/useCoreApiSource'
import { useStartDataIntegrityCheckerReportMutation, useListBackgroundJobsQuery, ListBackgroundJobsQuery, BackgroundJobType } from '../../../graphql/generated'

type BackgroundJob = ListBackgroundJobsQuery["listBackgroundJobs"][number]

type BackgroundJobOutcomeLinearProgressProps = {
  total: number,
  success: number,
  failed: number,
  errors: {
    name: string,
    errorRowIds?: string[] | null | undefined,
  }[],
}

const BackgroundJobOutcomeLinearProgress: React.FC<BackgroundJobOutcomeLinearProgressProps> = (props) => {
  // Ensure that total is > 0 ELSE set to 1 (avoids div by zero)
  const total = Math.abs(props.total) || 1

  // Ensure that success and failed are >= 0 <= total ELSE set to total
  const success = Math.abs(props.success) <= total ? Math.abs(props.success) : total
  const failed = Math.abs(props.failed) <= total ? Math.abs(props.failed) : total

  // Work out the success percentage against the total
  const successPct = Math.round((success / total) * 100)

  // Work out the failed percentage against the total
  const failedPct = Math.round((failed / total) * 100)

  // Popover Setup
  const [ anchorEl, setAnchorEl ] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
  const handlePopoverClose = () => setAnchorEl(null)

  const open = Boolean(anchorEl)

  return <>
    <Box
      sx={{ width: "100%" }}
      aria-owns={open ? 'mouse-over-popover' : undefined}
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <LinearProgress
        variant="buffer"
        value={successPct}
        valueBuffer={successPct + failedPct}
        sx={{
          height: "1em",
          "& .MuiLinearProgress-bar1Buffer": {
            backgroundColor: "green",
            backgroundImage: "none",
            animation: "none",
          },
          "& .MuiLinearProgress-bar2Buffer": {
            backgroundColor: "red",
            backgroundImage: "none",
            animation: "none",
          },
          "& .MuiLinearProgress-dashed": {
            backgroundColor: "lightgrey",
            backgroundImage: "none",
            animation: "none",
          },
        }}
      />
    </Box>
    <Popover
      id="mouse-over-popover"
      sx={{ pointerEvents: 'none' }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <Typography sx={{ p: 1 }}>Of {props.total} tables, {props.success} completed successfully and {props.failed} failed.</Typography>
      {(props.errors ?? []).map(error => (
        <Typography key={error.name} sx={{ p: 1 }}>Table {error.name} has errored on the following rows: {JSON.stringify(error.errorRowIds)}</Typography>
      ))}
    </Popover>
  </>
}

const BackgroundJobsIndex: React.FC = () => {
  const gqlDatasource = useCoreApiSource()

  const startDataIntegrityCheckerReportMutation = useStartDataIntegrityCheckerReportMutation(gqlDatasource)

  const listBackgroundJobsQuery = useListBackgroundJobsQuery(gqlDatasource, {}, {
    staleTime: 10 * 60 * 1000, // 10 minutes
    refetchOnWindowFocus: false,
  })

  const onClickRefreshBackgroundJobs = async () => {
    await listBackgroundJobsQuery.refetch()
  }

  const onClickStartDataIntegrityCheckerReportMutation = async () => {
    await startDataIntegrityCheckerReportMutation.mutateAsync({})
    await onClickRefreshBackgroundJobs()
  }

  const resultRenderCell: Record<BackgroundJobType, GridColDef<BackgroundJob>['renderCell']> = {
    DataIntegrityCheckerReport: params => {
      if (!params.row.payloadAsDataIntegrityCheckerReport) {
        return <div><em>Missing Typed Payload</em></div>
      }

      const { totalTableCount, successTableCount, failedTableCount, errors } = params.row.payloadAsDataIntegrityCheckerReport
      return <BackgroundJobOutcomeLinearProgress
        total={totalTableCount}
        success={successTableCount}
        failed={failedTableCount}
        errors={errors}
      />
    },
  }

  const columns = useMemo<GridColDef<BackgroundJob>[]>(() => [
    { sortable: false, flex: 1, minWidth: 300, field: 'id', headerName: 'Job Type', renderCell: (params) => <div>{params.row.id}</div> },
    { sortable: false, flex: 1, minWidth: 200, field: 'type', headerName: 'Job Type', renderCell: (params) => <div>{params.row.type}</div> },
    { sortable: false, flex: 1, minWidth: 100, field: 'status', headerName: 'Job Status', renderCell: (params) => <div>{params.row.status}</div> },
    { sortable: false, flex: 1, minWidth: 200, field: 'startedAt', headerName: 'Started At', renderCell: (params) => <div>{params.row.startedAt}</div> },
    { sortable: false, flex: 1, minWidth: 200, field: 'finishedAt', headerName: 'Finished At', renderCell: (params) => <div>{params.row.finishedAt}</div> },
    {
      sortable: false, flex: 1, minWidth: 300, field: 'results', headerName: 'Results', renderCell: params => {
        const result = resultRenderCell[params.row.type]
        return result && result(params)
      },
    },
  ], [])

  const data = listBackgroundJobsQuery.data?.listBackgroundJobs || []

  return (
    <Grid container rowSpacing={2} p={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <DataGrid
                  getRowId={row => row.id}
                  rows={data}
                  columns={columns}
                  disableSelectionOnClick={true}
                  rowHeight={110}
                  autoHeight={true}
                  disableColumnMenu
                />
              </Grid>
            </Grid>
            <ButtonGroup style={{ marginTop: '1rem' }}>
              <Button variant="contained" onClick={onClickRefreshBackgroundJobs}>Refresh Data</Button>
              <Button variant="contained" onClick={onClickStartDataIntegrityCheckerReportMutation}>Start Data Integrity Checker Report</Button>
            </ButtonGroup>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default BackgroundJobsIndex
