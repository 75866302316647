export type MenuItemData = {
  uid?: string,
  label?: string,
  leftIcon?: React.ReactNode,
  rightIcon?: React.ReactNode,
  onAfterClose?: () => void,
  items?: MenuItemData[],
}

export const colourValues: Record<string,string> = {
  Blue: '#d3e5ef',
  Red: '#ffe2dd',
  Yellow: '#fdecc8',
  Green: '#dbeddb',
}

export type StatusBody<TEnumValue extends string> = {
  label: string,
  progress: TEnumValue,
  color: string,
}

export type Status<TEnumValue extends string> = StatusBody<TEnumValue> & {
  id: string,
}
export type PersistedStatus<TEnumValue extends string> = StatusBody<TEnumValue> & {
  statusId: string,
}
