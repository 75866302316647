import * as React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { Input, InputAdornment, ListSubheader, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { objectEntries } from '../../utils/objects'
import { ChipStyledRenderValue } from './ChipStyledRenderValue'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: (ITEM_HEIGHT * 4.5) + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

type SelectorPropTypes<TEnum extends string> = {
  inputOptions: Record<TEnum, string>,
  onSelectUpdate: (value: string[], id: string) => void,
  initialData: string[],
  rowId?: string,
  isMultipleSelect: boolean,
  hasFilter?: boolean,
  renderComponent?: RenderComponent,
}

export enum RenderComponent {
  ChipStyledRenderValue,
}

/**
 * This is a generic selector component that supports single and multiple select.
 * It also supports a filterable dropdown aided by a text input.
 *
 * It is designed to be used with a Record<Tenum, string> as the input options.
 *
 * To process data from the selector, an onSelectUpdate function needs be passed in.
 * This allows the parent component to handle the data as required.
 *
 */
export const Selector = <Tenum extends string> (props: SelectorPropTypes<Tenum>) => {

  const [ searchText, setSearchText ] = React.useState("")
  const [ selectedData, setSelectedData ] = React.useState<string[]>(props.initialData)

  /**
   * Render value style controls the appearance of the selected items once the dropdown has closed.
   * It is defaulted to a chip style that is removable onClick
   *
   * This can be expanded to allow for other styles or functional control as we require
   */
  const renderValueStyle: Record<RenderComponent, (selected: Array<string>) => JSX.Element> = {
    [RenderComponent.ChipStyledRenderValue]: ChipStyledRenderValue({
      selectedData,
      setSelectedData,
      onSelectUpdate: props.onSelectUpdate,
      rowId: props.rowId,
    }),
  }

  const renderValue = props.renderComponent ? renderValueStyle[props.renderComponent] : renderValueStyle[RenderComponent.ChipStyledRenderValue]

  // Used to filter dropdown options based on the search text
  const containsText = (text: string, searchText: string) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1

  const iterableArrayFromInputOptions: [Tenum, string][] = objectEntries(props.inputOptions)

  const displayedOptions = React.useMemo(
    () => iterableArrayFromInputOptions.filter(([ , value ]) => containsText(value, searchText)),
    [ searchText ],
  )

  // The type value coming back from the event is possibly a string so we
  // perform the isArray check to ensure we always return an array
  const handleChange = (event: SelectChangeEvent<typeof selectedData>) => {
    const {
      target: { value },
    } = event

    const updatedValueAsArray: string[] = Array.isArray(value) ? value : [ value ]

    setSelectedData(updatedValueAsArray)
    props.onSelectUpdate && props.onSelectUpdate(updatedValueAsArray, props.rowId || "")
  }

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel>Document type</InputLabel>
        <Select
          multiple={props.isMultipleSelect}
          value={selectedData}
          onChange={handleChange}
          onClose={() => setSearchText("")}
          input={<Input />}
          renderValue={renderValue}
          MenuProps={MenuProps}
        >

          {props.hasFilter && <ListSubheader>
            <TextField
              size="small"
              autoFocus
              placeholder="Type to search..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation()
                }
              }}
            />
          </ListSubheader>}

          {displayedOptions.map(([ key, value ]) => (
            <MenuItem
              key={key}
              value={value}
            >
              {props.inputOptions[key]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
