import { useForm, Controller } from "react-hook-form"
import { Button, Select, TextField, Typography, Stack, OutlinedInput, MenuItem, Checkbox, ListItemText, FormControl, InputLabel } from "@mui/material"
import z from "zod"
import { zodResolver } from '@hookform/resolvers/zod'
import { DateTime } from 'luxon'
import { Project } from "../../types"
import { getBudgetRangeByAmountInPennies } from "../../../../common/utils/budgetRanges"

const zForm = z.object({
  projectTypes: z.array(z.string()),
  locations: z.array(z.string()),
  budgetRange: z.string().optional(),
  preference: z.string().optional(),
  from: z.string().optional(),
  to: z.string().optional(),
})

const projectTypes = [
  'SideExtension',
  'RearExtension',
  'ChangeOfUse',
  'LoftConversion',
  'Conservatory',
  'FullRenovation',
  'NewBuild',
  'Refurbishment',
  'BasementConversion',
]

const preference = [
  'Any',
  'Architect',
  'Homeowner',
]

const locations = [
  'LondonCentral',
  'LondonEast',
  'LondonNorth',
  'LondonSouth',
  'LondonWest',
]

const budgetRanges = [
  '',
  'F30T100',
  'F100T500',
  'F500T1000',
  'F1000T3000',
]

type RequestInputForm = z.infer<typeof zForm>
export type RequestInputOnSubmit = (values: RequestInputForm) => unknown

const RequestInput = ({ onSubmit, project, button = 'Search' }: { onSubmit: RequestInputOnSubmit, project: Project, button?: string }) => {
  const { control, getValues, trigger, formState: { errors } } = useForm<RequestInputForm>({
    defaultValues: {
      projectTypes: project.projectTypes,
      budgetRange: getBudgetRangeByAmountInPennies(Number(project.budgetValue.amountInPence)),
      locations: locations,
      preference: 'Any',
      from: DateTime.now().minus({ days: 30 }).setLocale('en-gb').toISODate(),
      to: DateTime.now().setLocale('en-gb').toISODate(),
    },
    resolver: zodResolver(zForm),
  })

  const onHandleSubmit = () => {
    trigger()
    const values = getValues()
    onSubmit(values)
  }

  return (<>
    <Stack spacing={2} direction="row">
      <Controller
        name="projectTypes"
        control={control}
        render={({ field }) => (
          <FormControl sx={{ width: 150 }}>
            <InputLabel id="demo-multiple-name-label">Project Types</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              {...field}
              multiple
              input={<OutlinedInput label="Project Types" />}
              renderValue={(selected) => selected.join(', ')}
            >
              {projectTypes.map((item) => (
                <MenuItem key={item} value={item}>
                  <Checkbox checked={field.value.indexOf(item) > -1} />
                  <ListItemText primary={item} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      <Controller
        name="locations"
        control={control}
        render={({ field }) => (
          <FormControl sx={{ width: 150 }}>
            <InputLabel id="location-label">Location</InputLabel>
            <Select
              labelId="location-label"
              id="location-id"
              {...field}
              multiple
              input={<OutlinedInput label="Location" />}
              renderValue={(selected) => selected.join(', ')}
            >
              {locations.map((item) => (
                <MenuItem key={item} value={item}>
                  <Checkbox checked={field.value.indexOf(item) > -1} />
                  <ListItemText primary={item} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      <Controller
        name="preference"
        control={control}
        render={({ field }) => (
          <FormControl sx={{ width: 150 }}>
            <InputLabel id="preference-label">Reference pref</InputLabel>
            <Select
              labelId="preference-label"
              id="preference-id"
              {...field}
              input={<OutlinedInput label="Reference pref" />}
            >
              {preference.map((item) => (
                <MenuItem key={item} value={item}>
                  <ListItemText primary={item} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      <Controller
        name="budgetRange"
        control={control}
        render={({ field }) => (
          <FormControl sx={{ width: 150 }}>
            <InputLabel id="budget-range-label">Budget Range</InputLabel>
            <Select
              labelId="budget-range-label"
              id="budget-range-id"
              {...field}
              input={<OutlinedInput label="Budget Range" />}
            >
              {budgetRanges.map((item) => (
                <MenuItem key={item} value={item}>
                  <ListItemText primary={item} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      <Controller
        name="from"
        control={control}
        render={({ field }) => (
          <FormControl sx={{ width: 150 }}>
            <TextField
              label="From"
              type="date"
              {...field}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        )}
      />
      <Controller
        name="to"
        control={control}
        render={({ field }) => (
          <FormControl sx={{ width: 150 }}>
            <TextField
              label="To"
              type="date"
              {...field}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        )}
      />
      <Button onClick={onHandleSubmit} variant="contained">{button}</Button>
    </Stack>

    {errors.projectTypes && <Typography>Select at least one project type!</Typography> }
  </>
  )
}

export default RequestInput
