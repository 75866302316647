import { useCallback, useEffect } from "react"
import { SegmentContext } from "../../Bootstrapper"
import { EventProperties, NoData } from "./events"
import { useRequiredContext } from "./useRequiredContext"

type EventName = keyof EventProperties

/**
 * PRIVATE: internal function for calling any analytics function with any data
 * It's used so that useAnalyticsEvent/useFireAnalyticsEvent can simplify their external type safety without changing functionality
 *
 * It is NOT intented for external consumption - please do NOT consume/export without consultation
 */
const useUnsafeAnalyticsEvent = (eventName: EventName) => {
  const analytics = useRequiredContext(SegmentContext)

  const trackFn = useCallback(async (extraData = {}) => {
    console.debug(`[useUnsafeAnalyticsEvent.trigger] Send event '${eventName}': `, extraData)
    await analytics.track(eventName, extraData)
  }, [ analytics ])

  return trackFn
}

/**
 * Use this hook when you need to get a function to call the analytics with.
 * Returns a function which will fire the event named when called with optional extra data.
 *
 * @param eventName EventName
 * @returns A function to fire the event named, with the optional extra data
 */
export const useAnalyticsEvent = <TEventName extends EventName>(eventName: TEventName): (...[ extraData ]: EventProperties[TEventName] extends NoData ? [] : [EventProperties[TEventName]]) => Promise<void> => {
  return useUnsafeAnalyticsEvent(eventName)
}

/**
 * Fires the event named with the optional extra data whenever the dependencies specified change.
 *
 * @param props EventName, optional extra data, and change dependency array
 */
type UseFireAnalyticsEventProps<TEventName extends EventName> =  {
  eventName: TEventName,
  extraData?: EventProperties[TEventName] extends NoData ? never : EventProperties[TEventName],
  deps?: unknown[],
}
export const useFireAnalyticsEvent = <TEventName extends EventName>({ eventName, extraData, deps = [] }: UseFireAnalyticsEventProps<TEventName>) => {
  const triggerEvent = useUnsafeAnalyticsEvent(eventName)

  // This gets called multiple times if `useMemo` is used instead!
  useEffect(() => {
    triggerEvent(extraData)
  }, deps)
}
