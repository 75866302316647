import React, { useMemo, useState } from 'react'
import { Button, CircularProgress, Link, Modal, Typography, Alert } from '@mui/material'
import { Box } from '@mui/system'
import { DataGrid, GridCellParams, GridColDef, MuiEvent } from '@mui/x-data-grid'
import { useCoreApiSource } from '../../common/hooks/useCoreApiSource'
import { useGetProjectDocumentsQuery, UploadedFileStatus, UploadedFileKind, useSetUploadedFileKindMutation, ContentDisposition } from '../../graphql/generated'
import { ProjectDocument } from './types'
import { saveFileToDownloadsFolder } from '../../common/utils/files'
import { ArchiveDocumentButton } from './ArchiveDocument'
import { DateTime } from 'luxon'
import { asyncForEach } from '../../common/utils'
import { Selector } from '../../common/components/Selector/Selector'
import { objectEntries } from '../../common/utils/objects'
import { useAnalyticsEvent } from '../../common/hooks/analytics'

type TagArchiveDocumentModalProps = {
  openModal: boolean,
  handleCloseModal: () => void,
  projectId: string,
  projectAddress?: string,
}

export const TagArchiveDocumentModal: React.FC<TagArchiveDocumentModalProps> = (props) => {

  const gqlDatasource = useCoreApiSource()
  const fireEvent_Ops_Project_Document_Tagged = useAnalyticsEvent('Ops_Project_Document_Tagged')
  const [ showAlert, setShowAlert ] = useState<boolean>(false)

  const documentQueryResult = useGetProjectDocumentsQuery(gqlDatasource, {
    id: props.projectId,
    ignoreScope: true,
    config: { disposition: ContentDisposition.Inline, transformation: { width: 1000, height: 1000 } },
  },
  {
    refetchOnWindowFocus: false,
  })

  const updateDocumentTag = useSetUploadedFileKindMutation(gqlDatasource)

  const { openModal, handleCloseModal } = props

  const documentTypeLabels: Record<UploadedFileKind, string> = {
    [UploadedFileKind.Planning]: "Planning",
    [UploadedFileKind.Sow]: "SOW",
    [UploadedFileKind.Technical]: "Technical",
    [UploadedFileKind.Structural]: "Structural",
  }

  const handleDownloadDocument = async (document: ProjectDocument) => {
    const url = document.signedUrlForDownload.url
    const fileName = document.fileName

    if (!url) return console.error("[TagArchiveDocumentModal]: No URL for Document")
    if (!fileName) return console.error("[TagArchiveDocumentModal]: No filename for Document")

    const result = await fetch(url)
    const blob = await result.blob()
    saveFileToDownloadsFolder(fileName, blob)
  }

  const handleDownloadAll = async () => {
    if (!nonArchivedDocuments) return console.error("[TagArchiveDocumentModal]: No documents to download")

    asyncForEach(nonArchivedDocuments, async (document: ProjectDocument) => {
      await handleDownloadDocument(document)
    })
  }

  const handleUpdateDocumentTag = (value: string[], documentId: string) => {

    const updatedData: UploadedFileKind[]  = objectEntries(documentTypeLabels).filter(([ , documentLabel ]) =>
      value.includes(documentLabel)).map(([ key ]) => key)

    updateDocumentTag.mutateAsync({
      uploadedFileKind: updatedData,
      uploadedFileId: documentId,
    }, {
      onSuccess: () => {
        console.debug(`[TagArchiveDocumentModal] Successfully tagged document with Kind @ documentId: ${documentId}`)
        fireEvent_Ops_Project_Document_Tagged({
          projectId: props.projectId,
          documentId: documentId,
          type: updatedData,
        })
      },
      onError: () => {
        console.debug(`[TagArchiveDocumentModal] Failed to tag document with Kind @ documentId: ${documentId}`)
        setShowAlert(true)
      },
    })
  }

  const columns = useMemo<GridColDef<ProjectDocument>[]>(() => [
    { sortable: false, flex: 1, maxWidth: 500, field: 'name', headerName: 'Document name', headerAlign: 'center', renderCell: (params) => <Link sx={{ cursor: 'pointer' }} target='_blank' href={params.row.signedUrlForDownload?.url ?? undefined} ><Typography variant="h6">{`${props.projectAddress} - ${params.row.fileName}`}</Typography></Link> },
    { sortable: false, flex: 1, maxWidth: 200, minWidth: 200, field: 'date', align: 'center', headerName: 'Upload date', headerAlign: 'center', renderCell: (params) => <Typography variant="body1">{DateTime.fromISO(params.row.updatedAt).toFormat('dd/MM/yyyy')}</Typography> },
    { sortable: false, flex: 1, maxWidth: 350, minWidth: 350, field: 'type', headerName: 'Type', headerAlign: 'center', renderCell: (params) => <Selector inputOptions={documentTypeLabels} hasFilter={true} isMultipleSelect={true} rowId={params.row.id} initialData={params.row.kind ?? []} onSelectUpdate={handleUpdateDocumentTag} /> },
    { sortable: false, flex: 1, maxWidth: 100, minWidth: 100, field: 'archive', align: 'center', headerName: 'Archive', headerAlign: 'center', renderCell: (params) => <ArchiveDocumentButton documentId={params.row.id} projectId={props.projectId} type={params.row.kind ?? []} /> },
  ], [])

  const { data, error, isLoading, isFetching } = documentQueryResult

  const documentData = data?.getProject?.documents
  const includeNonArchivedDocuments = (document: ProjectDocument) => document?.status !== UploadedFileStatus.Archived
  const nonArchivedDocuments = documentData?.filter(includeNonArchivedDocuments)

  if (error) {
    console.error("[TagArchiveDownloadModal]:", error)
  }

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1200,
        height: 800,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }}>
        {isFetching || isLoading
          ? <CircularProgress />
          : !nonArchivedDocuments?.length || error
            ? <Typography textAlign='center' variant="h6">No non-archived documents found</Typography>
            : <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button sx={{ marginBottom: 2 }} onClick={handleDownloadAll} variant="contained">Download all</Button>
                <Button sx={{ marginBottom: 2 }} onClick={handleCloseModal} variant="outlined">Close</Button>
              </Box>
              <Box sx={{ height: 700, width: '100%' }}>
                <DataGrid
                  rowHeight={80}
                  rows={nonArchivedDocuments}
                  columns={columns}
                  disableSelectionOnClick={true}
                  disableColumnMenu={true}
                  onCellClick={(params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
                    // This fixes an error being thrown on click of our select disabled rows
                    event.stopPropagation()
                  }}
                />
              </Box>
            </>}
        {showAlert && <Alert severity="error">Sorry adding the document tag has failed - Please refresh the browser</Alert>}
      </Box>
    </Modal>
  )
}
