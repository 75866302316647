import { Button } from '@mui/material'
import React from 'react'
import { useCoreApiSource } from '../../../common/hooks/useCoreApiSource'
import { useGetMechaReleaseInfoQuery, useFlushCachesFrontendSpaMutation } from '../../../graphql/generated'

const ReleasesIndex: React.FC = () => {
  const gqlDatasource = useCoreApiSource()

  const getMechaReleaseInfoQuery = useGetMechaReleaseInfoQuery(gqlDatasource, {}, {
    staleTime: 10 * 60 * 1000, // 10 minutes
    refetchOnWindowFocus: false,
  })

  const flushCachesFrontendSPA = useFlushCachesFrontendSpaMutation(gqlDatasource)

  const mechaVersion = getMechaReleaseInfoQuery.data?.getReleaseInfo.mechaVersion
  const appFrontendVersion = getMechaReleaseInfoQuery.data?.getReleaseInfo.appFrontendVersion
  const opsDashboardInfrastructureVersion = getMechaReleaseInfoQuery.data?.getReleaseInfo.opsDashboardInfrastructureVersion
  const opsDashboardFrontendVersion = getMechaReleaseInfoQuery.data?.getReleaseInfo.opsDashboardFrontendVersion

  const opsDashboardFrontendRunning = process.env.REACT_APP_RELEASE_VERSION ?? "unknown"

  return <>
    <p><Button onClick={() => getMechaReleaseInfoQuery.refetch()}>Refetch Release Information</Button></p>
    <h2>Deployed Release Versions</h2>
    <p><b>Mecha Deployed:</b> <code>{mechaVersion}</code></p>
    <p><b>App Frontend Deployed:</b> <code>{appFrontendVersion}</code> <Button onClick={() => flushCachesFrontendSPA.mutateAsync({})}>invalidate</Button></p>
    <p><b>Ops Dashboard Infrastructure Deployed:</b> <code>{opsDashboardInfrastructureVersion}</code></p>
    <p><b>Ops Dashboard Frontend Deployed:</b> <code>{opsDashboardFrontendVersion}</code></p>
    <h2>Release Version running in this browser</h2>
    <p><b>Ops Dashboard Frontend Running:</b> <code>{opsDashboardFrontendRunning}</code></p>
  </>
}

export default ReleasesIndex
