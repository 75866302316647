import { useAddContractorProfileWorkHistoryReferenceNoteMutation,useListAllContractorProfileWorkHistoryReferenceNotesQuery, useListAllContractorProfileWorkHistoryReferenceStatusesQuery, useSelectContractorProfileWorkHistoryReferenceStatusMutation, useUpdateContractorProfileWorkHistoryReferenceStatusMutation, useAddContractorProfileWorkHistoryReferenceStatusMutation, ProgressionStatus, ListAllOpsWorkHistoryReferencesQuery, useListAllOpsWorkHistoryReferencesQuery, ListAllOpsWorkHistoryReferencesFilter } from '../../graphql/generated'
import { useCoreApiSource } from '../../common/hooks/useCoreApiSource'
import { useSearchState } from '../../common/hooks/pages'
import React, { useMemo } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import { useDebounce } from 'use-debounce'
import CircularProgress from '@mui/material/CircularProgress'
import NotesWidget from '../../common/components/NotesWidget'
import StatusWidget from '../../common/components/Status/StatusWidget'
import { getBudgetRangeByAmountInPennies } from '../../common/utils/budgetRanges'
import { budgetRangeLabels } from '../../common/utils/budgetRanges.i18n'
import { useAnalyticsEvent } from '../../common/hooks/analytics'
import { Button, Chip } from '@mui/material'
import { useRequiredContext } from '../../common/hooks/useRequiredContext'
import { EnvContext } from '../../Bootstrapper'
import { Duration } from 'luxon'

const contractorProfileWorkHistoryReferenceProgressLabels: Record<ProgressionStatus, string> = {
  [ProgressionStatus.Completed]: "Completed",
  [ProgressionStatus.NotStarted]: "Not Started",
  [ProgressionStatus.WaitingOnUser]: "Waiting on User",
  [ProgressionStatus.WaitingOnWeaver]: "Waiting on Weaver",
}

type OpsWorkHistoryReference = ListAllOpsWorkHistoryReferencesQuery["listAllOpsWorkHistoryReferences"][number]

const ConstructionValueBudgetRange: React.FC<{constructionValue: OpsWorkHistoryReference["constructionValue"]}> = ({ constructionValue }) => {
  const range = getBudgetRangeByAmountInPennies(Number(constructionValue?.amountInPence || 0))
  const label = range ? budgetRangeLabels[range] : "None"

  return (
    <div>{label}</div>
  )
}

const BuildersIndex: React.FC = () => {
  const { REACT_APP_APP_URL } = useRequiredContext(EnvContext)

  const gqlDatasource = useCoreApiSource()

  const [ searchState, mergeNext ] = useSearchState<ListAllOpsWorkHistoryReferencesFilter>({})
  const [ debouncedSearch ] = useDebounce(searchState, 500)

  const hasIdFilter = !!searchState.idEquals

  const query = useListAllOpsWorkHistoryReferencesQuery(gqlDatasource, {
    filter: debouncedSearch,
  }, { refetchOnWindowFocus: false })

  const useListAllNotes = (id: string) => useListAllContractorProfileWorkHistoryReferenceNotesQuery(gqlDatasource, { id }, {
    // notes query starts disabled because notesCount is populated from the main query (cached _notesCount)
    enabled: false,
    staleTime: Duration.fromObject({ seconds: 30 }).toMillis(),
    select: ({ listAllContractorProfileWorkHistoryReferenceNotes }) => listAllContractorProfileWorkHistoryReferenceNotes,
  })
  const useAddNote = () => useAddContractorProfileWorkHistoryReferenceNoteMutation(gqlDatasource)

  const useListAllStatuses = () => useListAllContractorProfileWorkHistoryReferenceStatusesQuery(gqlDatasource, {}, {
    staleTime: Duration.fromObject({ seconds: 30 }).toMillis(),
    select: ({ listAllContractorProfileWorkHistoryReferenceStatuses }) => listAllContractorProfileWorkHistoryReferenceStatuses,
  })

  const useSelectStatus = () => useSelectContractorProfileWorkHistoryReferenceStatusMutation(gqlDatasource)
  const useUpdateStatus = () => useUpdateContractorProfileWorkHistoryReferenceStatusMutation(gqlDatasource)
  const useAddStatus = () => useAddContractorProfileWorkHistoryReferenceStatusMutation(gqlDatasource)

  const fireEvent_Ops_WorkHistoryReference_Notes_Added = useAnalyticsEvent('Ops_WorkHistoryReference_Notes_Added')
  const fireEvent_Ops_WorkHistoryReference_Status_Set = useAnalyticsEvent('Ops_WorkHistoryReference_Status_Set')
  const fireEvent_Ops_WorkHistoryReference_Status_Create = useAnalyticsEvent('Ops_WorkHistoryReference_Status_Create')
  const fireEvent_Ops_WorkHistoryReference_Status_Update = useAnalyticsEvent('Ops_WorkHistoryReference_Status_Update')

  const columns = useMemo<GridColDef<OpsWorkHistoryReference>[]>(() => [
    { sortable: false, flex: 1, minWidth: 200, field: 'name', headerName: 'Name', renderCell: (params) => <div>{`${params.row.givenName} ${params.row.familyName}`}<div>{params.row.teamType}</div></div> },
    { sortable: false, flex: 1, minWidth: 200, field: 'band', headerName: 'Band', renderCell: ({ row }) => <ConstructionValueBudgetRange constructionValue={row.constructionValue} /> },
    { sortable: false, flex: 1, minWidth: 200, field: 'builder', headerName: 'Builder', renderCell: ({ row }) => <div>{row.companyTradingAs || row.companyRegisteredName}</div> },
    { sortable: false, flex: 1, minWidth: 200, field: 'status', headerName: 'Status', renderCell: (params) => <StatusWidget
      progressionLabels={contractorProfileWorkHistoryReferenceProgressLabels}
      parentId={params.row.id}
      initialStatusId={params.row.contractorProfileWorkHistoryReferenceStatusId ?? undefined}
      useNewStatusMutation={useAddStatus}
      useSelectStatusMutation={useSelectStatus}
      useStatusQuery={useListAllStatuses}
      useUpdateStatusMutation={useUpdateStatus}
      onAfterSelectStatusMutation={async (oldStatus, newStatus) => {
        await fireEvent_Ops_WorkHistoryReference_Status_Set({
          teamId: params.row.id,
          fieldName: params.colDef.field,
          oldStatus: oldStatus?.label,
          oldWeaverStatus: oldStatus?.progress,
          newStatus: newStatus.label,
          newWeaverStatus: newStatus.progress,
        })
      }}
      onAfterNewStatusMutation={async (newStatus) => {
        await fireEvent_Ops_WorkHistoryReference_Status_Create({
          teamId: params.row.id,
          fieldName: params.colDef.field,
          oldStatus: undefined,
          oldWeaverStatus: undefined,
          newStatus: newStatus.label,
          newWeaverStatus: newStatus.progress,
        })
      }}
      onAfterUpdateStatusMutation={async (oldStatus, newStatus) => {
        await fireEvent_Ops_WorkHistoryReference_Status_Update({
          teamId: params.row.id,
          fieldName: params.colDef.field,
          oldStatus: oldStatus?.label,
          oldWeaverStatus: oldStatus?.progress,
          newStatus: newStatus.label,
          newWeaverStatus: newStatus.progress,
        })
      }}
    /> },
    { sortable: false, flex: 1, minWidth: 200, field: 'notes', headerName: 'Notes', renderCell: (params) => <NotesWidget
      initialNoteCount={params.row._notesCount || 0}
      parentId={params.row.id}
      title={`${params.row.givenName} ${params.row.familyName}`}
      useAddNoteMutation={useAddNote}
      useListNotesQuery={useListAllNotes}
      onAfterAddNoteMutation={async () => {
        await fireEvent_Ops_WorkHistoryReference_Notes_Added({ teamId: params.row.id })
      }}
    /> },
    { sortable: false, flex: 1, minWidth: 200, field: 'actions', headerName: 'Actions', renderCell: (params) => <div>
      <Button variant='contained' onClick={() => {
        const compositeId = params.row.id // See `listAllOpsWorkHistoryReferences.ts` in Mecha
        const decomposedId = compositeId.split('|')
        if (decomposedId.length !== 3) throw new Error('Unsupported composite ID to WorkHistory reference')

        const referenceId = decomposedId[2]
        const referencesUrl = `${REACT_APP_APP_URL}/external/reference/${referenceId}`

        const tab = window.open(referencesUrl, '_blank')
        tab && tab.focus()
      }}>View</Button>
    </div> },
  ], [])

  const data = query.data?.listAllOpsWorkHistoryReferences || []

  return (
    <Grid container rowSpacing={2} p={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container rowSpacing={2}>
              <Grid item xs={6} sx={{ display: "flex", alignItems:"center", gap: "1rem" }}>
                <TextField value={searchState.nameContains || ""} onChange={(e) => mergeNext({ nameContains: e.target.value })} label="Search" variant="outlined">
                </TextField>
                {hasIdFilter && <Chip label="Viewing: Single Reference" onDelete={() => mergeNext({ idEquals: undefined })} />}
                { query.isLoading && <CircularProgress /> }
              </Grid>
              <Grid item xs={6}>
                {!!query.error && (
                  <Alert severity="error">
                    <AlertTitle>Error loading data</AlertTitle>
                  </Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <DataGrid
                  rows={data}
                  columns={columns}
                  disableSelectionOnClick={true}
                  rowHeight={110}
                  autoHeight={true}
                  disableColumnMenu
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default BuildersIndex
