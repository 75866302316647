import React from 'react'
import { Box, Chip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

type ChipStyledRenderValueProps = {
  selectedData: Array<string>,
  setSelectedData: (value: Array<string>) => void,
  onSelectUpdate?: (value: string[], id: string) => void,
  rowId?: string,
}

export const ChipStyledRenderValue = ({ selectedData, setSelectedData, onSelectUpdate, rowId } : ChipStyledRenderValueProps) => {

  return (selected: Array<string>) => {

    const handleDelete = (value: string) => {
      const updatedSelectedList = selectedData.filter((item) => item !== value)
      setSelectedData(updatedSelectedList)
      onSelectUpdate && onSelectUpdate(updatedSelectedList, rowId || "")
    }

    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {selected.map((value) => (
          <Chip
            sx={{ cursor: "pointer" }}
            deleteIcon={<CloseIcon onMouseDown={(event) => event.stopPropagation()}/>}
            onDelete={() => handleDelete(value)} key={value} label={value} />
        ))}
      </Box>
    )
  }
}
