import { Chip } from '@mui/material'
import { UseQueryResult } from '@tanstack/react-query'
import React from 'react'
import { colourValues, Status } from './common'

type StatusChipProps<TEnum extends Record<string, string>> = {
  id: string,
  useFindStatus: (id: string) => UseQueryResult<Status<TEnum[keyof TEnum]> | undefined, unknown>,
}

/** A generic component for displaying a non-interactable status */
export const StatusChip = <TEnum extends Record<string, string>>({ id,  useFindStatus }: StatusChipProps<TEnum>) => {
  const statusQuery = useFindStatus(id)

  if (!statusQuery.data) return null
  const status = statusQuery.data
  const color = colourValues[status.color]

  return (
    <Chip label={status.label} sx={{ backgroundColor: color }} />
  )
}
