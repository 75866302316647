import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useUpdateUploadedFileMutation, useGetProjectDocumentsQuery, UploadedFileStatus, UploadedFileKind } from '../../graphql/generated'
import { useQueryClient } from '@tanstack/react-query'
import { useCoreApiSource } from '../../common/hooks/useCoreApiSource'
import { useAnalyticsEvent } from '../../common/hooks/analytics'

type ArchiveDocumentProps = {
  documentId: string,
  projectId: string,
  type: UploadedFileKind[],
}

export const ArchiveDocumentButton: React.FC<ArchiveDocumentProps> = (props) => {

  const queryClient = useQueryClient()
  const gqlDatasource = useCoreApiSource()
  const fireEvent_Ops_Project_Document_Archived = useAnalyticsEvent('Ops_Project_Document_Archived')

  const updateUploadFileMutation = useUpdateUploadedFileMutation(gqlDatasource, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(useGetProjectDocumentsQuery.getKey({ id: props.projectId }))
      setShowArchiveDialog(false)
      fireEvent_Ops_Project_Document_Archived(
        {
          projectId: props.projectId,
          documentId: props.documentId,
          type: props.type,
        },
      )
    },
    onError: () => {
      setShowArchiveDialog(false)
      console.error("Error archiving document")
    },
  })

  const [ showArchiveDialog, setShowArchiveDialog ] = React.useState(false)

  const handleArchiveConfirmation = async () => {
    if (!props.documentId) return console.error("[TagArchiveDocumentModal]: No document ID to archive")

    await updateUploadFileMutation.mutateAsync({
      input: {
        id: props.documentId,
        status: UploadedFileStatus.Archived,
      },
    })
  }

  return (
    <>
      <IconButton onClick={() => setShowArchiveDialog(true)}><DeleteIcon /></IconButton>
      <Dialog
        open={showArchiveDialog}
        onClose={() => setShowArchiveDialog(false)}
      >
        <DialogTitle>
          Are you sure you want to archive this document?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will prevent the document being visible to all users.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowArchiveDialog(false)}>Cancel</Button>
          <Button onClick={handleArchiveConfirmation} autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
