import Button from '@mui/material/Button'
import { DataGrid } from '@mui/x-data-grid/DataGrid'
import { GridColDef } from '@mui/x-data-grid/models/colDef'
import React, { ComponentProps, useCallback, useMemo, useState } from 'react'
import { useCoreApiSource } from '../../../common/hooks/useCoreApiSource'
import { BudgetRange, ContractorProgress, ListContractorSuggestionsForProjectQuery, ProjectMemberRole, useAddProjectInviteMutation, useListAllContractorStatusQuery, useListContractorSuggestionsForProjectQuery } from '../../../graphql/generated'
import AddIcon from "@mui/icons-material/Add"
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded'
import { InviteTeamToProjectState, Project } from '../types'
import { useRequiredContext } from '../../../common/hooks/useRequiredContext'
import { ProjectsIndexContext } from '../ProjectsIndexContext'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Styles from "./BuilderSuggestionList.module.scss"
import Tooltip from '@mui/material/Tooltip'
import { EnvContext } from '../../../Bootstrapper'
import { Dialog, DialogActions, Link, Stack, Tab, Tabs } from '@mui/material'
import { useAnalyticsEvent } from '../../../common/hooks/analytics'
import { useBuildersSearchMutate, useMatchApiDataSource } from '../../../common/hooks/useMatchApi'
import RequestInput, { RequestInputOnSubmit } from './MatchingDashboard/RequestInput'
import { useWeaverFlags } from '../../../api/thirdparty/launchDarkly/useWeaverFlags'
import { getBudgetRangeByAmountInPennies, shouldPayForLead } from '../../../common/utils/budgetRanges'
import Table from './MatchingDashboard/Table'
import './MatchingDashboard/Table.css'

import { ContractorStatusChip } from '../../../common/components/Status/ContractorStatusChip'
import { useFindContractorStatus } from '../../../common/hooks/useFindContractorStatus'

export type ContractorProfile = ListContractorSuggestionsForProjectQuery["listContractorSuggestionsForProject"][0];

type BuilderSuggestionActionButtonProps = {
  profile: ContractorProfile,
  project: Project,
  onClick: (team: ContractorProfile, budgetRangeSubscriptions: BudgetRange[] | undefined, shouldContractorPayForLead: boolean) => unknown | void,
} & Omit<ComponentProps<typeof Button>, "onClick">
const BuilderSuggestionActionButton: React.FC<BuilderSuggestionActionButtonProps> = ({ onClick, profile, project, ...buttonProps }) => {
  const { ["MW-2414-prevent-sinbin-invites"]: preventSinbinInvites } = useWeaverFlags()
  const status = useFindContractorStatus(profile.statusId)
  const [ confirming, setConfirming ] = useState(false)

  const budgetRangeSubscriptions = profile.budgetRangeSubscriptions?.map(({ budgetRange }) => budgetRange)
  const shouldContractorPayForLead = shouldPayForLead(project, profile)

  const requiresConfirmation = preventSinbinInvites && status.data && status.data.progress === ContractorProgress.Fail

  const handleInvite = useCallback(() => onClick(profile, budgetRangeSubscriptions, shouldContractorPayForLead), [ onClick, profile ])
  const handleConfirm = useCallback(() => setConfirming(true), [ setConfirming ])
  const handleConfirmCancel = useCallback(() => setConfirming(false), [ setConfirming ])
  const handleConfirmSuccess = useCallback(async () => {
    await handleInvite()
    setConfirming(false)
  }, [ setConfirming, handleInvite ])

  const handleClick = requiresConfirmation ? handleConfirm : handleInvite
  return <>
    <Button variant='outlined' color={"success"} onClick={handleClick} {...buttonProps}><AddIcon />Invite</Button>
    {
      requiresConfirmation
        ? <Dialog
          open={confirming}
          onClose={handleConfirmCancel}
        >
          <DialogContent>
            <Typography>
              {profile.companyTradingAs || profile.companyRegisteredName} is in a failed status, are you sure you want to invite them?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button>Dismiss</Button>
            <Button variant='outlined' color="success" onClick={handleConfirmSuccess} {...buttonProps}><AddIcon />Invite</Button>
          </DialogActions>
        </Dialog>
        : null
    }
  </>
}

type BuilderSuggestionListProps = {
  modalState: InviteTeamToProjectState,
  onHandleClose?: () => void,
}

interface TabPanelProps {
  children?: React.ReactNode,
  index: number,
  value: number,
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>{children}</Box>
      )}
    </div>
  )
}

const BuilderSuggestionName: React.FC<{contractorProfile: ContractorProfile}> = ({ contractorProfile }) => {
  const name = contractorProfile.companyTradingAs || contractorProfile.companyRegisteredName
  const { REACT_APP_APP_URL } = useRequiredContext(EnvContext)

  const contractorUrl = `${REACT_APP_APP_URL}/profile/CONTRACTOR/${contractorProfile.id}`
  return (<Box>
    <Typography><Link target="_blank" href={contractorUrl}>{name}</Link></Typography>
    {contractorProfile.companyNumber &&
      <Tooltip title="Company Number">
        <Typography className={Styles.secondaryInfo} variant="body2" color={"text.secondary"} >
          <BusinessCenterRoundedIcon />{contractorProfile.companyNumber}
        </Typography>
      </Tooltip>
    }
  </Box>)
}

const BuilderSuggestionList: React.FC<BuilderSuggestionListProps> = ({ modalState: { project }, onHandleClose }) => {
  const { ["MW-2414-prevent-sinbin-invites"]: preventSinbinInvites } = useWeaverFlags()
  const gqlDatasource = useCoreApiSource()
  const matchApiDataSource = useMatchApiDataSource()
  const { handleModalSuccess } = useRequiredContext(ProjectsIndexContext)
  const [ value, setValue ] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(parseInt(newValue))
  }

  const searchBuilders = useBuildersSearchMutate(matchApiDataSource)
  const weaverFlags = useWeaverFlags()

  const exactBudgetRangeOfProject = getBudgetRangeByAmountInPennies(Number(project.budgetValue.amountInPence))

  const query = useListContractorSuggestionsForProjectQuery(gqlDatasource, { projectId: project.id }, { refetchOnWindowFocus: false })
  const addProjectInvite = useAddProjectInviteMutation(gqlDatasource)

  const fireInviteMutationAndEvent = async (team: Pick<ContractorProfile, 'id'>, budgetRangeSubscriptions: BudgetRange[] | undefined, shouldContractorPayForLead: boolean) => {
    await addProjectInvite.mutateAsync({ projectId: project.id, teamId: team.id, projectMemberRole: ProjectMemberRole.CandidateProfessional }, {
      onSuccess: async (data, variables) => {
        await fireEvent_Ops_Invite_Sent({
          teamId: variables.teamId,
          projectId: project.id,
          types: project.projectTypes,
          description: project.description,
          currency: project.budgetValue.currency,
          budgetInPence: project.budgetValue.amountInPence,
          budgetCategory: project.budgetCategory,
          budgetRange: exactBudgetRangeOfProject,
          budgetRangeSubscriptions: budgetRangeSubscriptions,
          shouldPayForLead: shouldContractorPayForLead,
          workStartEstimate: project.workStartEstimate,
          tenderReturnDate: weaverFlags['MW-2386-remove-tender-return-date']
            ? undefined
            : project?.tenderReturnDate ?? undefined,
        })
        handleModalSuccess(project.id)
      },
    })
  }

  const fireEvent_Ops_Invite_Sent = useAnalyticsEvent('Ops_Invite_Sent')

  const handleAdd = useCallback((team: Pick<ContractorProfile, 'id'>, budgetRangeSubscriptions: BudgetRange[] | undefined, shouldContractorPayForLead: boolean) => fireInviteMutationAndEvent(team, budgetRangeSubscriptions, shouldContractorPayForLead), [])

  const handleSubmit: RequestInputOnSubmit = (values) => {
    searchBuilders.mutate({
      ...values,
      from: new Date(values.from ?? '').toISOString(),
      to: new Date(values.to ?? '').toISOString(),
    })
  }

  const columns = useMemo<GridColDef<ContractorProfile>[]>(() => [
    { sortable: false, flex: 1, minWidth: 200, field: 'companyRegisteredName', headerName: 'Name', renderCell: (params) => <BuilderSuggestionName contractorProfile={params.row} /> },
    ...(preventSinbinInvites ? [ { sortable: false, maxWidth: 200, flex: 1, field: 'statusId', headerName: 'Status', renderCell: (params) => <ContractorStatusChip statusId={params.row.statusId} /> } as GridColDef<ContractorProfile> ] : []),
    { sortable: false, minWidth: 125, field: 'acceptedLeads', valueFormatter: (params) => (params.value || 0), headerName: 'Accepted Leads' },
    { sortable: false, minWidth: 125, field: 'rejectedLeads', valueFormatter: (params) => (params.value || 0), headerName: 'Rejected Leads' },
    { sortable: false, flex: 1, minWidth: 100, maxWidth: 150, field: 'actions', headerName: 'Actions', align: "right", renderCell: (params) => <BuilderSuggestionActionButton profile={params.row} project={project} onClick={handleAdd} disabled={addProjectInvite.isLoading} /> },
  ], [ handleAdd, addProjectInvite.isLoading, preventSinbinInvites ])

  const data = query.data?.listContractorSuggestionsForProject || []
  const matchingData = searchBuilders.data || []

  return (
    <>
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CloseIcon sx={{ marginRight: '0.25rem', cursor: 'pointer' }} onClick={onHandleClose} />Invite Contractor to Project
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Matching API" />
            <Tab label="Suggestion list" />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <RequestInput onSubmit={handleSubmit} project={project} />
          {!!data.length && (
            <Stack overflow='auto' mt={2}>
              <Table data={matchingData} onInviteClick={(teamId: string, budgetRanges: BudgetRange[]) => {
                const budgetRangeSubscriptions = budgetRanges.map(budgetRange => {
                  return {
                    budgetRange,
                  }
                })

                const contractor = {
                  id: teamId,
                  budgetRangeSubscriptions,
                }
                const shouldContractorPayForLead = shouldPayForLead(project, contractor)
                handleAdd(contractor, budgetRanges, shouldContractorPayForLead)
              }
              } />
            </Stack>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DataGrid
            rows={data}
            columns={columns}
            disableSelectionOnClick={true}
            autoHeight={true}
            disableColumnMenu
          />
        </TabPanel>
      </DialogContent>
    </>
  )
}

export default BuilderSuggestionList
