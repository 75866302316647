import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import { Button, Menu, FormControl, Box, Select,MenuItem, InputLabel, RadioGroup, FormControlLabel, Radio, TextField, styled, SelectChangeEvent, Typography, Skeleton } from '@mui/material'
import { NestedMenuItem } from './NestedMenuItem'
import { Add, MoreVert } from "@mui/icons-material"
import { UseMutationResult, UseQueryResult } from '@tanstack/react-query'
import { objectEntries } from '../../utils/objects'
import { grey } from '@mui/material/colors'
import { colourValues, PersistedStatus, Status, StatusBody } from './common'

const StyledMenuItem = styled(Box)({
  paddingLeft: '4px',
  paddingRight: '4px',
  paddingTop: '1px',
  paddingBottom: '1px',
  display: 'flex',
  backgroundColor: '#d7d7d7',
  justifyContent: 'space-between',
})

const StyledEditMenu = styled(Box)({
  paddingLeft: '10px',
  paddingRight: '10px',
  paddingTop: '1px',
  minWidth: '280px',
  paddingBottom: '1px',
  display: 'flex',
  flexDirection: 'column',
  gap: "10px",
})

export type StatusWidgetProps<TEnum extends Record<string, string>, TEnumValue extends TEnum[keyof TEnum]> = {
  parentId: string,
  initialStatusId?: string | null,
  progressionLabels: Record<TEnumValue, string>,
  useStatusQuery:() => UseQueryResult<Status<TEnumValue>[], unknown>,
  useSelectStatusMutation: () => UseMutationResult<unknown, unknown, {parentId: string, statusId: string}>,
  useNewStatusMutation: () => UseMutationResult<unknown, unknown, { label:string, color: string, progress: TEnumValue}>,
  useUpdateStatusMutation: () => UseMutationResult<unknown, unknown, {statusId: string, label: string, color: string, progress: TEnumValue}>,
  onAfterSelectStatusMutation: (oldStatus: Status<TEnumValue> | undefined, updatedStatus: Status<TEnumValue>) => Promise<unknown> | unknown,
  onAfterNewStatusMutation?: (newStatus: StatusBody<TEnumValue>) => Promise<unknown> | unknown,
  onAfterUpdateStatusMutation?: (oldStatus: PersistedStatus<TEnumValue> | undefined, updatedStatus: PersistedStatus<TEnumValue>) => Promise<unknown> | unknown,
}

const UNKNOWN_COLOR = grey[400]

const StatusWidget = <TEnum extends Record<string, string>, TEnumValue extends TEnum[keyof TEnum]> ({ parentId, initialStatusId, progressionLabels, useStatusQuery,useSelectStatusMutation, useNewStatusMutation, useUpdateStatusMutation, onAfterSelectStatusMutation, onAfterNewStatusMutation, onAfterUpdateStatusMutation }:StatusWidgetProps<TEnum, TEnumValue>) => {
  const enumEntries = useMemo<[keyof TEnum, TEnumValue][]>(() => objectEntries(progressionLabels), [ progressionLabels ])
  const defaultProgressionKey = enumEntries[0][0]

  const [ isInitialized, setInitializedState ] = useState(false)
  const setInitialized = () => setInitializedState(true)

  const [ anchorEl, setAnchorEl ] = useState(null)
  const [ statusWeaver, setStatusWeaver ] = useState<keyof TEnum>()
  const [ statusUpdateWeaver, setStatusUpdateWeaver ] = useState<keyof TEnum>(defaultProgressionKey)
  const [ draftStatus, setDraftStatus ] = useState<Partial<StatusBody<TEnumValue>>>({})
  const [ editingDraftStatus, setEditingDraftStatus ] = useState<Status<TEnumValue>|undefined>(undefined)
  const [ selectedStatus, setSelectedStatus ] = useState<Status<TEnumValue> | undefined>(undefined)
  const query = useStatusQuery()

  const selectStatusMutation = useSelectStatusMutation()
  const newStatusMutation = useNewStatusMutation()
  const updateStatusMutation = useUpdateStatusMutation()

  type ProgressStatusMap = { [key in Status<TEnumValue>["progress"]]: Status<TEnumValue>[] }

  const modifyEditingDraftStatus = (update: Partial<Status<TEnumValue>>) => {
    if (!editingDraftStatus) throw new Error(`[StatusWidget] ${parentId}, recieved modify event with no status being edited`)

    setEditingDraftStatus({ ...editingDraftStatus, ...update })
  }

  const selectStatus = useCallback(async (_e:unknown, data:Status<TEnumValue>) => {
    if (data.id) {
      await selectStatusMutation.mutateAsync({ parentId:parentId, statusId: data.id })
      await onAfterSelectStatusMutation(selectedStatus, data)
      await setSelectedStatus(data)
      await setAnchorEl(null)
    }
  }, [ selectedStatus, setSelectedStatus, setAnchorEl, onAfterSelectStatusMutation ])

  const statusByProgress = useMemo(() => {
    const rawStatuses = query.data || []
    return rawStatuses.reduce((prev, item) => {
      const existing = prev[item.progress] || []
      return {
        ...prev,
        [item.progress]: [ ...existing, item ],
      }
    }, {} as ProgressStatusMap)
  }, [ query.data ])

  // Bind data -> selectedStatus
  useEffect(() => {
    if (!query.data) return

    // if there's already a status, use the latest data from query.data to update it
    // as it may have been updated (create/update)
    if (selectedStatus) {
      const updatedStatus = query.data.find(status => status.id === selectedStatus.id)
      setSelectedStatus(updatedStatus)
      setInitialized()
      return
    }

    if (isInitialized) return
    // if the widget has not been initialized yet, use the initial status from prop
    const initialStatus = query.data.find(status => status.id === initialStatusId)
    setSelectedStatus(initialStatus)
    setInitialized()
  }, [ query.data, selectedStatus, setSelectedStatus ])

  const isOpen = Boolean(anchorEl)

  const handleClick = (e: React.MouseEvent) => setAnchorEl(e.currentTarget as any)
  const handleClose = (_e: unknown, data?: Status<TEnumValue>) => {
    if (data) setSelectedStatus(data)
    setAnchorEl(null)
  }

  const onSubmitNewStatus = useCallback(async () => {
    const { color, label, progress } = draftStatus
    if (color && label && progress){
      await newStatusMutation.mutateAsync({ color, label, progress })
      !!onAfterNewStatusMutation && (await onAfterNewStatusMutation({ color, label, progress }))
      await query.refetch()
    }
  }, [ query.refetch, draftStatus, setDraftStatus ])

  const handleSelectedEditStatus = async (id: string) => {
    const result = query.data?.find(status => status.id === id)
    if (result) {
      await setEditingDraftStatus(result)
      await setStatusUpdateWeaver(result.progress)
    }
  }

  const handleEditStatus = useCallback(async () => {
    if (editingDraftStatus?.id){
      const oldStatus = selectedStatus && { statusId: editingDraftStatus.id, ...selectedStatus }
      const newStatus = { statusId: editingDraftStatus.id, ...editingDraftStatus }
      await updateStatusMutation.mutateAsync(newStatus)
      !!onAfterUpdateStatusMutation && (await onAfterUpdateStatusMutation(oldStatus, newStatus))
      await query.refetch()
    }
  }, [ query.refetch, editingDraftStatus, setEditingDraftStatus ])

  return <>
    {(!query.isInitialLoading && isInitialized) ? (
      <Button
        variant="contained"
        onClick={handleClick}
        sx={{
          background: selectedStatus ? colourValues[selectedStatus.color] : UNKNOWN_COLOR,
          ":hover": {
            background: selectedStatus ? colourValues[selectedStatus.color] : UNKNOWN_COLOR,
          },
          color: "black" }}
      >
        <Typography noWrap sx={{ textTransform: "none" }}>{ selectedStatus ? selectedStatus.label: "Select Status" }</Typography>
      </Button>
    ) : <Skeleton animation="wave" variant="rectangular" width={94} height={24} />}

    <Menu anchorEl={anchorEl} open={isOpen} onClose={(e) => handleClose(e)}>
      {objectEntries(statusByProgress).map(([ progressKey ]) => {
        return (
          <div key={JSON.stringify(statusByProgress[progressKey])}>
            <StyledMenuItem>{progressionLabels[progressKey as TEnumValue]}</StyledMenuItem>
            { statusByProgress[progressKey].map((status) => (

              <Box key={status.id} sx={{ display:"flex", flexDirection:"row", justifyContent: "space-between", alignItems: "center" }}>
                <MenuItem onClick={(e) => selectStatus(e, status)}><div style={{ backgroundColor: colourValues[status.color] , paddingLeft: "7px", paddingRight: "7px", borderRadius: "4px", fontSize: "0.9em" }}>{status.label}</div></MenuItem>
                <NestedMenuItem
                  key={status.id}
                  parentMenuOpen={isOpen}
                  button="isIconOnly"
                  rightIcon={<MoreVert />}
                  onClick={() => handleSelectedEditStatus(status.id)}
                >
                  <StyledEditMenu>
                    <TextField onClick={(e) => e.stopPropagation()} value={editingDraftStatus?.label} onChange={(e) => modifyEditingDraftStatus( { label:e.target.value })}
                      placeholder="Status name..." variant="outlined" fullWidth size="small" />
                    <div style={{ width: "100%" }}>
                      <FormControl fullWidth>
                        <InputLabel size='small'>Progress</InputLabel>
                        <Select
                          onClick={(e) => e.stopPropagation()}
                          value={statusUpdateWeaver}
                          label="Progress"
                          fullWidth
                          size='small'
                          onChange={(e: SelectChangeEvent<keyof TEnum>) => {
                            // Casting to the enum because the SelectChangeEvent resolves to `e.target.value: string | TEnum[keyof TEnum]`
                            // We can be sure this is safe because we use objectEntries of the same TEnum below
                            const enumChangedTo = e.target.value as TEnumValue
                            setStatusUpdateWeaver(enumChangedTo)
                            modifyEditingDraftStatus( { progress: enumChangedTo })
                          }}
                        >
                          {objectEntries(progressionLabels).map(([ enumValue, label ]) => {
                            // Confirming that progressionLabels is of type TEnum
                            const typedEnum: TEnum[keyof TEnum] = enumValue
                            return <MenuItem key={typedEnum} value={typedEnum}>{label as string}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <FormControl>
                      <RadioGroup
                        value={editingDraftStatus?.color}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => modifyEditingDraftStatus( { color: e.target.value })}
                      >
                        {objectEntries(colourValues).map(([ color, hex ]) => (
                          <FormControlLabel key={color} value={color} control={<Radio sx={{
                            color: hex,
                            '&.Mui-checked': {
                              color: hex,
                            },
                          }} />} label={color} />
                        ))}
                      </RadioGroup>
                    </FormControl>
                    <Button disabled={updateStatusMutation.isLoading} onClick={handleEditStatus}  fullWidth size="small" variant="contained">Save</Button>
                  </StyledEditMenu>
                </NestedMenuItem>
              </Box>
            ))}
          </div>
        )
      })}
      <NestedMenuItem
        rightIcon={<Add />}
        label="Add"
        parentMenuOpen={isOpen}
        button
      >
        <StyledEditMenu>
          <TextField onClick={(e) => e.stopPropagation()} placeholder="Status name..." value={draftStatus.label} onChange={(e) => setDraftStatus( (prev) => ({  ...prev, label:e.target.value }))} variant="outlined" fullWidth size="small" />
          <div style={{ width: "100%" }}>
            <FormControl fullWidth>
              <InputLabel size='small'>Progress</InputLabel>
              <Select
                value={statusWeaver}
                label="Progress"
                fullWidth
                size='small'
                placeholder='Please select...'
                onClick={(e) => e.stopPropagation()}
                onChange={(e: SelectChangeEvent<keyof TEnum>) => {
                // Casting to the enum because the SelectChangeEvent resolves to `e.target.value: string | TEnum[keyof TEnum]`
                // We can be sure this is safe because we use objectEntries of the same enum
                  const enumChangedTo = e.target.value as TEnumValue
                  setStatusWeaver(enumChangedTo)
                  setDraftStatus(prev => ({  ...prev, progress: enumChangedTo }))
                }}
              >
                {objectEntries(progressionLabels).map(([ enumValue, label ]) => {
                // Confirming that progressionLabels is of type TEnum
                  const typedEnum: TEnum[keyof TEnum] = enumValue
                  return <MenuItem key={typedEnum} value={typedEnum}>{label as string}</MenuItem>
                })}
              </Select>
            </FormControl>
          </div>
          <FormControl>
            <RadioGroup
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => setDraftStatus( (prev) => ({  ...prev, color: e.target.value }))}
            >
              {objectEntries(colourValues).map(([ color, hex ]) => (
                <FormControlLabel key={color} value={color} control={
                  <Radio sx={{
                    color: hex,
                    '&.Mui-checked': {
                      color: hex,
                    },
                  }} />} label={color} />
              ))}
            </RadioGroup>
          </FormControl>
          <Button disabled={newStatusMutation.isLoading} onClick={onSubmitNewStatus} fullWidth size="small" variant="contained">Add new</Button>
        </StyledEditMenu>
      </NestedMenuItem>
    </Menu>
  </>
}

export default StatusWidget
