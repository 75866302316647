import { useFlags } from "launchdarkly-react-client-sdk"
import { z } from "zod"
import { objectKeys } from "../../../common/utils/objects"

/**
 * The schema for the LaunchDarkly flags that might come back.
 */
export const zWeaverFlags = z.object({
  'MW-1814-task-templates-page': z.boolean(),
  "task-templates": z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
      createProject: z.array(z.string()).optional(),
    }),
    z.object({
      enabled: z.literal(false),
    }),
  ]),
  'MW-2386-remove-tender-return-date': z.boolean(),
  'MW-2414-prevent-sinbin-invites': z.boolean(),
  'MW-2407-tag-docs-with-kind': z.boolean(),
})

export type WeaverFlags = z.infer<typeof zWeaverFlags>

export const defaultWeaverFlags: WeaverFlags = {
  'MW-1814-task-templates-page': false,
  "task-templates": { enabled: false },
  'MW-2386-remove-tender-return-date': false,
  'MW-2414-prevent-sinbin-invites': false,
  'MW-2407-tag-docs-with-kind': false,
}

/**
   * A function generator which takes a `zod shape` of an object,
   * along with a `candidate object` that *may* represent that shape,
   * and a `default object` *fully* represents that shape.
   *
   * It then produces an `Array.reduce()` function which iterates over each key in the `zod shape`.
   * If that key's value within the `candidate object` passes that key's zod shape, then it is taken.
   * Otherwise, that key's value from the `default object` is used.
   *
   * The primary use for this is to take the `LaunchDarkly` set of flags and to default only the flags which don't pass validation.
   *
   * @returns `Partial<X>` which should actually be a full `X` (where `X` matches the defaultObject type)
   */
export const defaultInvalidCandidateObjectValues = <T extends z.AnyZodObject, X = z.infer<T>>(zodShape: T, candidateObject: Partial<X>, defaultObject: X) =>
  (acc: Partial<X>, key: keyof X) => {
    const parseResult = zodShape.shape[key].safeParse(candidateObject[key])
    if (parseResult.success) {
      return { ...acc, [key]: candidateObject[key] }
    } else {
      console.error(`[useWeaverFlags.defaultInvalidCandidateObjectValues] Unable to parse the key '${String(key)}' as flags. Defaulting it.`, { error: parseResult.error, candidate: candidateObject[key], default: defaultObject[key] })
      return { ...acc, [key]: defaultObject[key] }
    }
  }

/**
 * Returns a strongly typed set of LaunchDarkly flags, or throws an error.
 * WARNING: Changes to LaunchDarkly config will change this behaviour!
 */
export const useWeaverFlags = (): WeaverFlags => {
  const flags = useFlags()

  // Cold start support - Default an empty object or undefined state
  if (!flags || Object.keys(flags).length === 0) {
    console.log('[useWeaverFlags] Cold start detected. Using defaultWeaverFlags for now.', flags)
    return defaultWeaverFlags
  }

  // Apply the reducer to the LaunchDarkly flags, evaluating each flag independently
  // It should return a full WeaverFlags object (as we iterate over every potential key and default), but Typescript can't be sure
  const weaverFlagReducer = defaultInvalidCandidateObjectValues(zWeaverFlags, flags, defaultWeaverFlags)
  const defaultedFlags: Partial<WeaverFlags> = objectKeys(zWeaverFlags.shape).reduce(weaverFlagReducer, {})

  // We rerun the parse across the whole object - this really should not fail!
  const parsedFlags = zWeaverFlags.safeParse(defaultedFlags)
  if (!parsedFlags.success) {
    console.error('[useWeaverFlags] CRITICAL! Unable to parse the whole flagset. This should never happen!', parsedFlags.error)
    return defaultWeaverFlags
  }

  console.debug('[useWeaverFlags] Successfully loaded WeaverFlags: ', parsedFlags)
  return parsedFlags.data
}
