import React, { useEffect } from 'react'
import { useWeaverFlags } from './api/thirdparty/launchDarkly/useWeaverFlags'
import { AuthContext, EnvContext } from './Bootstrapper'
import { useRequiredContext } from './common/hooks/useRequiredContext'

const MyWeaverConsole: React.FC = () => {
  const env = useRequiredContext(EnvContext)
  const auth = useRequiredContext(AuthContext)
  const flags = useWeaverFlags()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- use any to avoid type errors when creating new properties on window
    const windowRef = window as any
    windowRef.MyWeaverConsole = {
      auth,
      flags,
    }
  }, [ env, flags ])
  return null
}

export default MyWeaverConsole
