import { ListAllProjectsFilter, ListAllProjectsQuery, useListAllProjectsQuery, useAddProjectSlotMutation, useRefreshSingleProjectQuery, ProjectMemberRole, TeamType, useListAllProjectStatusQuery, useListProjectNotesQuery, useAddProjectNoteMutation, useCreateNewProjectStatusMutation, useSelectProjectStatusMutation, useUpdateProjectStatusMutation, ProjectProgress } from '../../graphql/generated'
import { ModalState, Project, ProjectMember, ProjectMemberInvite, ProjectMemberSlot } from "./types"
import { useCoreApiSource } from '../../common/hooks/useCoreApiSource'
import { useSearchState } from '../../common/hooks/pages'
import React, { useMemo, useState } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import TaskStatus from './widgets/TaskStatusDisplay'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import ContractorDisplay from './widgets/ContractorDisplay'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import { DateTime, Duration } from "luxon"
import { useDebounce } from 'use-debounce'
import CircularProgress from '@mui/material/CircularProgress'
import { useQueryClient } from '@tanstack/react-query'
import { ProjectsIndexContext, ProjectsIndexContextValue } from './ProjectsIndexContext'
import { ManageMember } from './dialogs/ManageMember'
import { ManageSlot } from './dialogs/ManageSlot'
import { ManageInvite } from './dialogs/ManageInvite'
import BuilderSuggestionList from './dialogs/BuilderSuggestionList'
import Dialog from '@mui/material/Dialog'
import Chip from '@mui/material/Chip'
import Styles from "./ProjectsIndex.module.scss"
import ContractorTotal from './widgets/ContractorTotal'
import OwnerDisplay from './widgets/OwnerDisplay'
import NotesWidget from '../../common/components/NotesWidget'
import StatusWidget from '../../common/components/Status/StatusWidget'
import { upsertIn } from '../../common/utils/queries'
import { useAnalyticsEvent } from '../../common/hooks/analytics'
import { EnvContext } from '../../Bootstrapper'
import path from 'path-browserify'
import { useRequiredContext } from '../../common/hooks/useRequiredContext'
import { getBudgetRangeByAmountInPennies } from '../../common/utils/budgetRanges'
import { budgetRangeLabels } from '../../common/utils/budgetRanges.i18n'
import OpenIcon from '@mui/icons-material/OpenInNew'
import { Description } from '@mui/icons-material'
import { Button, IconButton, Link, Tooltip, Typography } from '@mui/material'
import { TagArchiveDocumentModal } from './TagArchiveDocumentModal'
import { useWeaverFlags } from '../../api/thirdparty/launchDarkly/useWeaverFlags'

const ProjectSummary: React.FC<{project: Project}> = ({ project }) => {
  const { REACT_APP_APP_URL } = useRequiredContext(EnvContext)

  const projectUrl = useMemo(() => {
    const url = new URL(REACT_APP_APP_URL)
    url.pathname = path.join("/projects", project.id)
    url.searchParams.set("ignoreScope", "true")
    return url
  }, [ REACT_APP_APP_URL, project.id ])

  const projectBudgetLabel = useMemo(() => {
    const budgetRange = getBudgetRangeByAmountInPennies(Number(project.budgetValue.amountInPence))
    if (!budgetRange) return "Unknown Budget"
    return budgetRangeLabels[budgetRange]
  }, [ project.budgetValue.amountInPence ])

  const budgetExact = useMemo(() => Number(project.budgetValue.amountInPence)/100, [ project.budgetValue.amountInPence ])

  return <div>
    <Link href={projectUrl.href}>{project.title}</Link>
    <Tooltip title={`${budgetExact} ${project.budgetValue.currency}`}><div>{projectBudgetLabel}</div></Tooltip>
  </div>
}

const ProjectDocuments: React.FC<{project: Project}> = ({ project }) => {
  const { REACT_APP_APP_URL } = useRequiredContext(EnvContext)
  const [ openModal, setOpenModal ] = useState(false)
  const weaverFlags = useWeaverFlags()

  const documentsUrl = useMemo(() => {
    const url = new URL(REACT_APP_APP_URL)
    url.pathname = path.join("/projects", project.id, "documents")
    url.searchParams.set("ignoreScope", "true")
    return url
  }, [ REACT_APP_APP_URL, project.id ])

  const handleCloseModal = () => setOpenModal(false)

  return <div>
    { weaverFlags['MW-2407-tag-docs-with-kind']
      ? <IconButton onClick={() => setOpenModal(true)} aria-label="Tag a document with a kind"><Typography fontSize={12}>{project.documentCount || 0}</Typography> <Description color='success' /></IconButton>
      : <Button  href={documentsUrl.href} size="small">{project.documentCount || 0} <OpenIcon /></Button>
    }
    {openModal && <TagArchiveDocumentModal
      projectId={project.id}
      projectAddress={project.address?.addressLine1 ?? undefined}
      handleCloseModal={handleCloseModal}
      openModal={openModal} />}
  </div>
}

const ProjectsIndex: React.FC = () => {
  const queryClient = useQueryClient()
  const { ["MW-2414-prevent-sinbin-invites"]: preventSinbinInvites } = useWeaverFlags()

  const gqlDatasource = useCoreApiSource()
  const addSlot = useAddProjectSlotMutation(gqlDatasource)
  const [ searchState, mergeNext ] = useSearchState<ListAllProjectsFilter>({})
  const [ debouncedSearch ] = useDebounce(searchState, 500)

  const query = useListAllProjectsQuery(gqlDatasource, {
    filter: debouncedSearch,
  }, { refetchOnWindowFocus: false })

  const [ manageModalState, setManageModalState ] = useState<ModalState|undefined>(undefined)
  const handleManageMember = (project: Project, member: ProjectMember) => setManageModalState({ type: "member", project, member })
  const handleManageInvite = (project: Project, invite: ProjectMemberInvite) => setManageModalState({ type: "invite", project, invite })
  const handleManageSlot = (project: Project, slot: ProjectMemberSlot) => setManageModalState({ type: "slot", project, slot })
  const handleManageAddProjectInvite = (project: Project) => setManageModalState({ type: "addProjectInvite", project })

  const refreshSingleProject = async (projectId: string) => {
    console.debug(`[ProjectsIndex] refreshing project ${projectId}`)
    const old = queryClient.getQueryData<ListAllProjectsQuery>(useListAllProjectsQuery.getKey({ filter: debouncedSearch }))

    const { getProject: refreshedProject } = await queryClient.fetchQuery(useRefreshSingleProjectQuery.getKey({ projectId }), useRefreshSingleProjectQuery.fetcher(gqlDatasource, { projectId }))

    queryClient.setQueryData<ListAllProjectsQuery>(useListAllProjectsQuery.getKey({ filter: debouncedSearch }), () => {
      return {
        ...old,
        listAllProjects: upsertIn(old?.listAllProjects || [], x => x.id === refreshedProject.id, refreshedProject),
      }
    })
  }

  /** called when we exit a modal that we _know_ has updated the project */
  const handleModalSuccess = async (projectId: string) => {
    setManageModalState(undefined)
    await refreshSingleProject(projectId)
  }
  const handleModalCancel = () => setManageModalState(undefined)

  const handleAddContractorSlot = async (project: Project) => {
    await addSlot.mutateAsync({ input: { projectId: project.id, requiredProjectRole: ProjectMemberRole.CandidateProfessional, requiredTeamType: TeamType.Contractor } })
    await refreshSingleProject(project.id)
  }

  const indexContext: ProjectsIndexContextValue = {
    handleManageInvite,
    handleManageMember,
    handleManageSlot,
    handleManageAddProjectInvite,
    handleAddSlot: handleAddContractorSlot,
    handleModalSuccess,
    handleModalCancel,
  }

  const useListAllNotes = (id: string) => useListProjectNotesQuery(gqlDatasource, { id }, {
    // notes query starts disabled because notesCount is populated from the main query (cached _notesCount)
    enabled: false,
    staleTime: Duration.fromObject({ seconds: 30 }).toMillis(),
    select: ({ listProjectNotes }) => listProjectNotes,
  })

  const useAddNote = () => useAddProjectNoteMutation(gqlDatasource)

  const useListAllStatus = () => useListAllProjectStatusQuery(gqlDatasource, {}, {
    staleTime: Duration.fromObject({ seconds: 30 }).toMillis(),
    select: ({ listAllProjectStatus }) => listAllProjectStatus ,
  })

  const useCreateStatus = () => useCreateNewProjectStatusMutation(gqlDatasource)
  const useSelectMutation = () => useSelectProjectStatusMutation(gqlDatasource)
  const useUpdateStatus = () => useUpdateProjectStatusMutation(gqlDatasource)

  const ProjectProgression = {
    [ProjectProgress.Active] : "Active",
    [ProjectProgress.Inactive] : "Inactive",
  }

  const fireEvent_Ops_Project_Notes_Added = useAnalyticsEvent('Ops_Project_Notes_Added')
  const fireEvent_Ops_Project_Status_Set = useAnalyticsEvent('Ops_Project_Status_Set')
  const fireEvent_Ops_Project_Status_Create = useAnalyticsEvent('Ops_Project_Status_Create')
  const fireEvent_Ops_Project_Status_Update = useAnalyticsEvent('Ops_Project_Status_Update')

  const columns = useMemo<GridColDef<Project>[]>(() => [
    { sortable: false, flex: 1, minWidth: 200, field: 'title', headerName: 'Project', renderCell: (params) => <ProjectSummary project={params.row} /> },
    { sortable: false, flex: 1, maxWidth: 400, minWidth: 100, field: 'projectType', headerName: 'Type', renderCell: (params) => <div className={Styles.projectType}>{params.row.projectTypes.sort().map(pt => <Chip key={pt} label={pt} />)}</div> },
    { sortable: false, flex: 1, maxWidth: 200, minWidth: 150, field: 'status', headerName: 'Status', renderCell: (params) => <TaskStatus project={params.row} /> },
    { sortable: false, flex: 1, maxWidth: 100, field: 'documentCount', headerName: 'Documents', renderCell: (params) => <ProjectDocuments project={params.row} />  },
    { sortable: false, flex: 1, maxWidth: 180, field: 'builderCount', headerName: 'Weaver Builders to Find', renderCell: (params) => <ContractorTotal project={params.row} /> },
    { sortable: false, flex: 1, minWidth: 250, field: 'builders', headerName: 'Builders', renderCell: (params) => <ContractorDisplay project={params.row} /> },
    { sortable: false, flex: 1, minWidth: 250, field: 'owners', headerName: 'Owners', renderCell: (params) => <OwnerDisplay project={params.row} /> },
    {
      sortable: false,
      flex: 1,
      field: 'notes',
      headerName: 'Notes',
      renderCell: params => (
        <NotesWidget
          initialNoteCount={params.row._notesCount || 0}
          parentId={params.row.id}
          title={params.row.title}
          useAddNoteMutation={useAddNote}
          useListNotesQuery={useListAllNotes}
          onAfterAddNoteMutation={async () => {
            await fireEvent_Ops_Project_Notes_Added({ projectId: params.row.id })
          }}
        />
      ),
    },
    {
      sortable: false,
      minWidth: 200,
      maxWidth: 250,
      flex: 1,
      field: 'softStatus',
      headerName: 'Status',
      renderCell: params => (
        <StatusWidget
          parentId={params.row.id}
          progressionLabels={ProjectProgression}
          useStatusQuery={useListAllStatus}
          useNewStatusMutation={useCreateStatus}
          useSelectStatusMutation={useSelectMutation}
          useUpdateStatusMutation={useUpdateStatus}
          onAfterSelectStatusMutation={async (oldStatus, newStatus) => {
            await fireEvent_Ops_Project_Status_Set({
              projectId: params.row.id,
              fieldName: params.colDef.field,
              oldStatus: oldStatus?.label,
              oldWeaverStatus: oldStatus?.progress,
              newStatus: newStatus.label,
              newWeaverStatus: newStatus.progress,
            })
          }}
          onAfterNewStatusMutation={async (newStatus) => {
            await fireEvent_Ops_Project_Status_Create({
              projectId: params.row.id,
              fieldName: params.colDef.field,
              oldStatus: undefined,
              oldWeaverStatus: undefined,
              newStatus: newStatus.label,
              newWeaverStatus: newStatus.progress,
            })
          }}
          onAfterUpdateStatusMutation={async (oldStatus, newStatus) => {
            await fireEvent_Ops_Project_Status_Update({
              projectId: params.row.id,
              fieldName: params.colDef.field,
              oldStatus: oldStatus?.label,
              oldWeaverStatus: oldStatus?.progress,
              newStatus: newStatus.label,
              newWeaverStatus: newStatus.progress,
            })
          }}
          initialStatusId={params.row.statusId}
        />
      ),
    },
    {
      sortable: false,
      width: 200,
      field: 'lastActivity',
      headerName: 'Last Activity',
      valueFormatter: params => DateTime.fromISO(params.value).toRelativeCalendar(),
    },
  ], [])

  const data = query.data?.listAllProjects || []

  return (
    <ProjectsIndexContext.Provider value={indexContext}>
      <Grid container rowSpacing={2} p={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container rowSpacing={2}>
                <Grid item xs={6}>
                  <TextField value={searchState.titleContains || ""} onChange={(e) => mergeNext({ titleContains: e.target.value })} label="Search" variant="outlined">
                  </TextField>
                  { query.isLoading && <CircularProgress /> }
                </Grid>
                <Grid item xs={6}>
                  {!!query.error && (
                    <Alert severity="error">
                      <AlertTitle>Error loading projects list</AlertTitle>
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <DataGrid
                    rows={data}
                    columns={columns}
                    disableSelectionOnClick={true}
                    rowHeight={110}
                    autoHeight={true}
                    disableColumnMenu
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Dialog
        open={!!manageModalState}
        onClose={handleModalCancel}
        fullWidth={manageModalState?.type === "addProjectInvite"}
        maxWidth={preventSinbinInvites ? "xl": undefined}
      >
        <>
          {manageModalState?.type === "member" && <ManageMember modalState={manageModalState}/> }
          {manageModalState?.type === "invite" && <ManageInvite modalState={manageModalState}/> }
          {manageModalState?.type === "slot" && <ManageSlot modalState={manageModalState}/> }
          {manageModalState?.type === "addProjectInvite" && <BuilderSuggestionList modalState={manageModalState} onHandleClose={handleModalCancel}/> }
        </>
      </Dialog>
    </ProjectsIndexContext.Provider>

  )
}

export default ProjectsIndex
