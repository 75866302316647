import { Currency, Money } from "../../graphql/generated"
import { currencySymbols } from "./currency.i18n"

export type MoneyRange = {
  rangeBottom: Money,
  rangeTop: Money,
}

export const asMoney = (currency: Currency, amountInPenceAsNumber: number): Money => ({
  currency,
  amountInPence: `${amountInPenceAsNumber}`,
})

export const moneyAmountAsNumber = (money: Money): number => {
  try {
    return Number.parseInt(money.amountInPence)
  } catch (e) {
    console.error(`Money.amountInPence is not a number! [${money.amountInPence}]`)
    throw e
  }
}

export const moneyAmountAsNumberOnlyLogError = (money: Money): number | undefined => {
  try {
    return moneyAmountAsNumber(money)
  } catch (e) {
    return undefined
  }
}

export const numberToStringWithThousandCommas = (number: number): string =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

export type MoneyToTextConfig = {
  withPennies: boolean,
}
export const moneyToText = (money: Money | null | undefined, config?: MoneyToTextConfig): string | null | undefined => {
  if (!money) return money

  const symbol = currencySymbols[money.currency]

  const pounds = Math.floor(moneyAmountAsNumber(money) / 100)
  const poundsWithCommas = numberToStringWithThousandCommas(pounds)

  const pennies = moneyAmountAsNumber(money) % 100
  const penniesSuffix = config?.withPennies ? `.${pennies.toString().padStart(2, '0')}` : ''

  return `${symbol}${poundsWithCommas}${penniesSuffix}`
}

export const addPercentageToMoney = (money: Money, percentage: number): Money => {
  const amountInPence = moneyAmountAsNumber(money) ?? 0
  const changedAmountInPence = amountInPence + Math.round(amountInPence * percentage / 100) // Do the calculation
  const positiveChangedAmountInPence = changedAmountInPence > 0 ? changedAmountInPence : 0 // Round negative numbers up to 0
  return {
    ...money,
    amountInPence: `${positiveChangedAmountInPence}`,
  }
}

export const makeMoneyFlexible = (money: Money, flexPercentage: number): MoneyRange => {
  // If there's no flex, make sure we can use === by return the same money object twice
  if (flexPercentage === 0) {
    return {
      rangeBottom: money,
      rangeTop: money,
    }
  }

  return {
    rangeBottom: addPercentageToMoney(money, 0 - flexPercentage),
    rangeTop: addPercentageToMoney(money, flexPercentage),
  }
}
