import { Duration } from 'luxon'
import React from 'react'
import { useListAllContractorStatusQuery } from '../../../graphql/generated'
import { useCoreApiSource } from '../../hooks/useCoreApiSource'
import { StatusChip } from './StatusChip'

type ContractorStatusChipProps = {
  statusId?: string | null,
}

export const ContractorStatusChip: React.FC<ContractorStatusChipProps> = ({ statusId }) => {
  const gqlDatasource = useCoreApiSource()
  const useFindStatus = (id: string) => useListAllContractorStatusQuery(gqlDatasource, {}, {
    select: ({ listAllContractorStatus: data }) => data.find(x => x.id === id),
    staleTime: Duration.fromObject({ seconds: 30 }).toMillis(),
  })

  return statusId ? <StatusChip id={statusId} useFindStatus={useFindStatus} /> : null
}
