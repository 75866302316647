import React, { useMemo } from 'react'
import { ProjectMemberRole } from '../../../graphql/generated'

import { getInitials } from '../../../common/utils/text'
import Tooltip from '@mui/material/Tooltip'
import Styles from "./CommonMemberDisplays.module.scss"
import { useRequiredContext } from '../../../common/hooks/useRequiredContext'
import { ProjectsIndexContext } from '../ProjectsIndexContext'
import { Project } from "../types"
import { sortByCreated, sorted } from "../../../common/utils/sort"
import CandidateIcon from '@mui/icons-material/PersonOutline'
import InviteIcon from '@mui/icons-material/EmailOutlined'
import AddSlotIcon from '@mui/icons-material/AddCircle'
import AddInviteIcon from '@mui/icons-material/PersonAddAlt1Outlined'
import { Typography } from '@mui/material'

type BuilderDisplayProps = {
  project: Project,
}
const BuilderDisplay: React.FC<BuilderDisplayProps> = ({ project }) => {
  const { handleAddSlot, handleManageAddProjectInvite, handleManageInvite, handleManageMember, handleManageSlot } = useRequiredContext(ProjectsIndexContext)

  const slots = useMemo(() => project.memberSlots.sort(sortByCreated), [ project.memberSlots ])
  const candidates = useMemo(() => sorted(project.members.filter(x => x.projectRole === ProjectMemberRole.CandidateProfessional), sortByCreated), [ project.members ])

  const memberInvites = useMemo(() => project.memberInvites.sort(sortByCreated), [ project.memberInvites ])

  return (
    <div className={Styles.container}>
      <div className={Styles.chipRow}>
        <div className={Styles.rowIcon}><InviteIcon /></div>
        {memberInvites.map(x => {
          if (x.team) {
            return <Tooltip sx={{ bgcolor: 'warning.main', color: "white", border: 0 }} className={Styles.item} key={x.id} title={`Invite: ${x.team.name || "Unknown Name"}`} onClick={() => handleManageInvite(project, x)}>
              <Typography>{getInitials(x.team.name || "X")} </Typography>
            </Tooltip>
          }
          return <Tooltip sx={{ border: "0.1rem solid black" }} className={Styles.item} key={x.id} title={`Invite: ${x.companyName || "Unknown Name"}`} onClick={() => handleManageInvite(project, x)}>
            <Typography>{x.companyName ? getInitials(x.companyName) : "?"}</Typography>
          </Tooltip>
        })}
        <Tooltip sx={{ border: "0.1rem solid black" }} className={Styles.item} title="Invite Contractor" onClick={() => handleManageAddProjectInvite(project)}>
          <Typography><AddInviteIcon /></Typography>
        </Tooltip>
      </div>
      <div className={Styles.chipRow}>
        <div className={Styles.rowIcon}><CandidateIcon /></div>
        {candidates.map(x => {
          return <Tooltip sx={{ bgcolor: 'info.main', color: "white", border: 0 }} className={Styles.item} key={x.team.id} title={`${x.projectRole}: ${x.team.name || "Unknown"}`} onClick={() => handleManageMember(project, x)}>
            <Typography color="text.secondary">{getInitials(x.team.name || "X")} </Typography>
          </Tooltip>
        })}
        {slots.map(x => <Tooltip sx={{ border: "0.1rem solid black" }} className={Styles.item} key={x.id} title={`Slot: ${x.requiredTeamType} ${x.requiredProjectRole}`} onClick={() => handleManageSlot(project, x)} >
          <Typography color="text.info">*</Typography>
        </Tooltip>)}
        <Tooltip sx={{ border: "0.1rem solid black" }} className={Styles.item} title="Add Slot" onClick={() => handleAddSlot(project)}>
          <Typography><AddSlotIcon /> </Typography>
        </Tooltip>
      </div>

    </div>
  )
}

export default BuilderDisplay
