import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import Bootstrapper from './Bootstrapper'
import { ThemeProvider } from "@mui/material/styles"
import CssBaseline from '@mui/material/CssBaseline'
import { theme } from './theme'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
)

root.render(
  <React.StrictMode>
    <CssBaseline>
      <ThemeProvider theme={theme} >
        <BrowserRouter>
          <Bootstrapper />
        </BrowserRouter>
      </ThemeProvider>
    </CssBaseline>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
