import React from 'react'
import { Link } from 'react-router-dom'
import { useWeaverFlags } from '../../api/thirdparty/launchDarkly/useWeaverFlags'

const EngineeringIndex: React.FC = () => {
  const flags = useWeaverFlags()
  const taskTemplatesFlag = flags["MW-1814-task-templates-page"]
  return (
    <ul>
      <li><Link to='/engineering/releases'>Releases</Link></li>
      <li><Link to='/engineering/stripe'>Stripe</Link></li>
      <li><Link to='/engineering/backgroundJobs'>Background Jobs</Link></li>
      {taskTemplatesFlag && <li><Link to='/engineering/taskTemplates'>Task Templates</Link></li>}
    </ul>
  )
}

export default EngineeringIndex
